import { QuickFilters } from "@/common/components/quick-filters/quick-filters";
import { Spacer } from "@/common/components/spacer/spacer";
import React, { Suspense } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import {
  mapping,
  initialParams,
  stockExchangeParamsSchema,
} from "./stock-exchange.utils";
import { Metadata } from "@/common/components/metadata/metadata";
import CoinUpArrow from "@icons/coin-up-arrow.svg?react";
import { Outlook } from "./stock-exchange.outlook";
import { Failed, NoData, Pending } from "@/common/components/info/info";
import { Chart } from "@/common/components/chart/chart";
import {
  CommodityProvider,
  CurrencyProvider,
  Filters,
} from "./stock-exchange.chart";
import { PageTitle } from "@/common/components/page-title/page-title";
import { ClientError } from "@/api/errors";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";
import { Spinner } from "@/common/components/spinner/spinner";
import { TwoColumnLayout } from "../layout";
import { ErrorBoundary } from "react-error-boundary";

const path = "stock-exchange";
const charts = [
  {
    commodity: "WHEAT",
    source: "MATIF",
    periods: ["CURRENT", "NEXT"],
    unit: "/t",
    currency: "EUR",
  },
  {
    commodity: "RAPESEED",
    source: "MATIF",
    periods: ["CURRENT", "NEXT"],
    unit: "/t",
    currency: "EUR",
  },
  {
    commodity: "CRUDE_OIL",
    source: "BRENT",
    periods: ["CURRENT"],
    unit: "/barel",
    currency: "USD",
  },
] as const;

const StockExchange = () => {
  const { _ } = useLingui();
  return (
    <TwoColumnLayout
      right={false}
      left={{
        header: (
          <PageTitle>
            <Trans>Burza</Trans>
          </PageTitle>
        ),
        content: (
          <div className="flex basis-full flex-col">
            <Metadata title={_(msg`Burza`)} />
            <Spacer />
            <div className="flex flex-col gap-x-6 gap-y-8 md:mx-0 lg:grid lg:grid-cols-2">
              {charts.map((chart) => {
                return (
                  <Filters commodityId={chart.commodity} key={chart.commodity}>
                    {(filters) => (
                      <Chart.Container>
                        <Chart.Title>
                          {mapping[chart.commodity].title}
                        </Chart.Title>
                        <QuickFilters {...filters} />
                        <Chart.ChartContainer>
                          <ErrorBoundary
                            fallbackRender={({ error }) => (
                              <Failed error={error} />
                            )}
                          >
                            <Suspense fallback={<Spinner />}>
                              <CommodityProvider
                                currency={chart.currency}
                                periods={chart.periods}
                                key={chart.commodity}
                                commodityId={chart.commodity}
                              >
                                {({ data, status }) => (
                                  <>
                                    {status === "success" ? (
                                      <>
                                        <div className="mr-6 flex flex-wrap justify-between gap-y-1">
                                          <Chart.Price>
                                            {data[0].current}
                                            {chart.unit}
                                          </Chart.Price>
                                          {data[0].trend ? (
                                            <Chart.Trend>
                                              {data[0].trend}
                                            </Chart.Trend>
                                          ) : null}
                                          <Chart.Source>
                                            <Trans>Zdroj</Trans>: {chart.source}
                                          </Chart.Source>
                                        </div>
                                        <Chart
                                          currency={chart.currency}
                                          data={{
                                            current: data[0].rates,
                                            next: data[1]
                                              ? data[1].rates
                                              : undefined,
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <NoData>
                                        <Trans>Burza ještě neotevřela</Trans>
                                      </NoData>
                                    )}
                                    {chart.commodity === "WHEAT" ||
                                    chart.commodity === "RAPESEED" ? (
                                      <Outlook commodityId={chart.commodity} />
                                    ) : null}
                                  </>
                                )}
                              </CommodityProvider>
                            </Suspense>
                          </ErrorBoundary>
                        </Chart.ChartContainer>
                      </Chart.Container>
                    )}
                  </Filters>
                );
              })}

              <CurrencyProvider>
                {({ currency, data, filters, status }) => (
                  <Chart.Container>
                    <Chart.Title>
                      <CoinUpArrow /> {data?.title}
                    </Chart.Title>
                    <QuickFilters {...filters} />
                    <Chart.ChartContainer>
                      {status === "success" ? (
                        <>
                          <div className="mr-6 flex flex-wrap justify-between gap-y-1">
                            <Chart.Price>{data.current}</Chart.Price>
                            <Chart.Trend>{data.trend}</Chart.Trend>
                            <Chart.Source>
                              <Trans>Zdroj</Trans>:{" "}
                              <Trans context="European central bank">ECB</Trans>
                            </Chart.Source>
                          </div>
                          {data.rates.length > 0 ? (
                            <Chart
                              currency={currency}
                              data={{
                                current: data.rates,
                              }}
                            />
                          ) : (
                            <NoData />
                          )}
                        </>
                      ) : status === "pending" ? (
                        <Pending />
                      ) : status === "empty" ? (
                        <NoData />
                      ) : (
                        <Failed error={new ClientError()} />
                      )}
                    </Chart.ChartContainer>
                  </Chart.Container>
                )}
              </CurrencyProvider>
            </div>
            <Spacer />
          </div>
        ),
      }}
    ></TwoColumnLayout>
  );
};

const StockExchangeParams = ({ children }: React.PropsWithChildren) => {
  const searchParams = useStockExchangeParams();
  const { pathname } = useLocation();

  if (!searchParams) {
    return <Navigate to={`${pathname}?${initialParams}`} />;
  }

  return <>{children}</>;
};

const useStockExchangeParams = () => {
  const [searchParams] = useSearchParams();
  const parsedParams = stockExchangeParamsSchema.safeParse(
    Object.fromEntries(searchParams),
  );

  if (parsedParams.success) {
    return parsedParams.data;
  }
};

export { StockExchange, StockExchangeParams, useStockExchangeParams, path };
