/* eslint-disable no-console */
import * as Sentry from "@sentry/react";
import { AppModeSchema, getEnvironmentVariable } from "./env";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

class Logger {
  constructor(private appMode: AppModeSchema) {
    if (this.isRemoteLoggingEnabled()) {
      Sentry.init({
        dsn: "https://9ca524635148db6f53ce6c67ff523e31@o82643.ingest.us.sentry.io/4507186468290560",
        environment: this.appMode,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
        ],
        tracePropagationTargets: ["*"],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }

  public log(...args: unknown[]) {
    if (this.isTestRun()) {
      return;
    }
    console.log(...args);
  }

  public error(e: Error | unknown | undefined, ...args: unknown[]) {
    if (this.isTestRun()) {
      return;
    }
    console.error(e, ...args);

    if (this.isRemoteLoggingEnabled()) {
      Sentry.captureException(e);
    }
  }

  private isRemoteLoggingEnabled() {
    return this.appMode !== "local";
  }

  private isTestRun() {
    return import.meta.env.MODE === "test";
  }
}

const logger = new Logger(getEnvironmentVariable("APP_MODE"));

export { logger };
