import { ErrorMessage } from "@hookform/error-message";
import { HTMLInputTypeAttribute, InputHTMLAttributes } from "react";
import {
  FieldValues,
  Path,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";

const InputField = <Fields extends FieldValues>({
  disabled,
  label,
  name,
  type = "text",
  options = {},
  ...rest
}: {
  disabled?: boolean;
  label: React.ReactNode;
  name: Path<Fields>;
  options?: RegisterOptions<Fields, Path<Fields>>;
  type?: HTMLInputTypeAttribute;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "required">) => {
  const form = useFormContext<Fields>();

  return (
    <label className="group w-full text-can-slate-blue-gray" htmlFor={name}>
      {label}
      <input
        disabled={disabled}
        id={name}
        type={type}
        className="mt-2 block w-full rounded-lg border p-4 font-bold group-has-[[role=alert]]:border-can-russet"
        {...form.register(name, {
          ...options,
        })}
        {...rest}
      />
      <ErrorMessage
        errors={form.formState.errors}
        // @ts-expect-error I have no clue how to type this
        name={name}
        render={({ message }) => (
          <p role="alert" className="mt-2 text-left text-xs text-can-russet">
            {message}
          </p>
        )}
      />
    </label>
  );
};

export { InputField };
