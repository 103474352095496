import { CompanyToPlanType, getCompanies1 } from "@/generated/api/users";
import { queryOptions } from "@tanstack/react-query";
import { sortBy } from "lodash";
import { DateTime } from "luxon";

type Company = {
  id: number;
  number: string;
  country: string;
  name: string;
  plan: {
    type: CompanyToPlanType;
    expiresAt: string;
  };
};

const companiesQuery = () =>
  queryOptions({
    queryKey: ["companies"],
    queryFn: () => getCompanies1(),
    select: (companies) =>
      sortBy(
        companies.map(
          (company) =>
            ({
              id: company.id,
              number: company.companyNumber,
              country: company.country,
              name: company.name,
              plan: {
                type: company.planType,
                expiresAt: company.planExpiresAt
                  ? DateTime.fromISO(company.planExpiresAt).toLocaleString(
                      DateTime.DATE_SHORT,
                    )
                  : "-",
              },
            }) satisfies Company,
        ),
        (company) => company.name,
      ),
    staleTime: Infinity,
  });

export { companiesQuery };
export type { Company };
