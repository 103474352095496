import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { jwtDecode } from "jwt-decode";
import { intersection } from "lodash";
import { Permission, permissions } from "@/routes/admin/permissions";

const AdminGuard = ({ children }: React.PropsWithChildren) => {
  const isAdmin = useHasAnyAdminPermission();
  const nonAdminJsx = children ? null : <Navigate to="/" />;
  const adminJsx = children ?? <Outlet />;

  if (isAdmin === false) {
    return nonAdminJsx;
  } else if (isAdmin === true) {
    return adminJsx;
  } else {
    return null;
  }
};

const PermissionGuard = ({
  children,
  onDenied,
  permission,
}: React.PropsWithChildren<{
  onDenied?: React.ReactNode;
  permission: Permission;
}>) => {
  const hasPermission = useHasPermission(permission);

  if (hasPermission) {
    return children;
  } else if (hasPermission === false) {
    return onDenied;
  }

  return null;
};

const useHasAnyAdminPermission = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isAdmin, setIsAdmin] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const decoded = jwtDecode(token);

      setIsAdmin(intersection(decoded.permissions, permissions).length > 0);
    });
  }, [getAccessTokenSilently]);

  return isAdmin;
};

const useHasPermission = (permission: Permission) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isAble, setIsAble] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    getAccessTokenSilently().then((token) => {
      const decoded = jwtDecode(token);

      setIsAble(decoded.permissions.includes(permission));
    });
  }, [getAccessTokenSilently, permission]);

  return isAble;
};

export { AdminGuard, PermissionGuard, useHasPermission };
