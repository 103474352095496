import { useAuth0 } from "@auth0/auth0-react";
import invariant from "tiny-invariant";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { companyQuery, useUpdateCompanyMutation } from "./company-form.api";
import { splitCallingCodeAndCountry } from "@/common/utils/phone";
import {
  CompanyForm as CompanyFormComponent,
  Fields,
  getInitialValues,
} from "@/common/components/company-form/company-form";
import { useUserSettings } from "@/common/providers/user-settings-provider";
import AngleLeft from "@icons/angle-left.svg?react";
import { FullscreenModalLayout } from "@/routes/layout";
import { Button } from "@/common/components/button/button";
import { Trans } from "@lingui/macro";
import { Spacer } from "@/common/components/spacer/spacer";

const CompanyForm = () => {
  const queryClient = useQueryClient();
  const { user: _user } = useAuth0();
  invariant(_user);
  const userSettings = useUserSettings();
  const {
    data: { company, disabledFields },
  } = useSuspenseQuery(companyQuery());
  const updateCompany = useUpdateCompanyMutation();
  const initialValues = getInitialValues(company);

  const handleSubmit = (data: Fields) => {
    updateCompany.mutate(
      {
        id: company.id,
        companyForm: data.legalForm,
        vat: data.vat,
        address: data.address,
        contactName: data.contact.name,
        contactPosition: data.contact.role,
        contactPhone: {
          phoneNumber: data.contact.phone.phoneNumber,
          countryCode: splitCallingCodeAndCountry(
            data.contact.phone.countryCode,
          ).at(0)!,
        },
        contactEmail: data.contact.email,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ["company-info"],
          });
          userSettings.dispatch({ type: "open", screen: "success" });
        },
        onError: () => {
          userSettings.dispatch({ type: "open", screen: "failure" });
        },
      },
    );
  };
  return (
    <FullscreenModalLayout
      headerButton={
        <button
          onClick={() => userSettings.dispatch(userSettings.state.closeAction)}
        >
          <AngleLeft />
        </button>
      }
    >
      <Spacer className="h-6" />
      <h1 className="text-2xl font-black text-can-forest-teal">
        <Trans>Informace o společnosti</Trans>
      </h1>
      <Spacer className="h-6" />
      <CompanyFormComponent
        disabledFields={disabledFields}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
      <Button.Container>
        <Button
          disabled={updateCompany.status === "pending"}
          type="submit"
          variant="primary"
          form="company-form"
        >
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </FullscreenModalLayout>
  );
};

export { CompanyForm };
