import { User } from "@auth0/auth0-react";
import { z } from "zod";
import { logger } from "./logger";

type UserState = "complete" | "incomplete";

const getUserState = (user: User): UserState => {
  try {
    getUser(user);
    return "complete";
  } catch (e) {
    logger.error(e);
    return "incomplete";
  }
};

const approvalStatus = z.union([
  z.literal("WAITING"),
  z.literal("APPROVED"),
  z.literal("BLOCKED"),
]);

const commonSchema = z.object({
  phone: z.object({
    phoneNumber: z.string(),
    countryCode: z.string(),
  }),
});
const CANSchema = z.object({
  companies: z
    .array(
      z.object({
        companyNumber: z.string(),
        countryCode: z.string(),
      }),
    )
    .nullish(),
  settings: z
    .object({
      language: z.string(),
      news: z
        .object({
          language: z.string(),
        })
        .or(z.undefined()),
    })
    .or(z.undefined()),
});

const userMetadataSchema = z.object({
  common: commonSchema,
  can: CANSchema,
});

const appMetadataSchema = z
  .object({
    companies: z
      .array(
        z.object({
          companyNumber: z.string(),
          approvalState: approvalStatus,
          plan: z.object({
            type: z.union([
              z.literal("FREEMIUM"),
              z.literal("BASIC"),
              z.literal("PREMIUM"),
            ]),
            expiresAt: z.coerce.date(),
          }),
        }),
      )
      .nullish(),
  })
  .nullish();

const userSchema = z.object({
  user_metadata: userMetadataSchema,
  "can/app_metadata": appMetadataSchema,
  name: z.string(),
  email: z.string(),
});

const incompleteUserSchema = userSchema.deepPartial();

type UserSchema = z.infer<typeof userSchema>;
type IncompleteUserSchema = z.infer<typeof incompleteUserSchema>;
type ApprovalStatus = z.infer<typeof approvalStatus>;

const getUser = (user: User) => userSchema.parse(user);

const getMaybeUser = (user: User) => incompleteUserSchema.parse(user);

const isBlocked = (
  user: z.infer<typeof userSchema> | z.infer<typeof incompleteUserSchema>,
) =>
  user["can/app_metadata"]?.companies?.every(
    (company) => company.approvalState === "BLOCKED",
  ) ?? false;

export { getUserState, getUser, getMaybeUser, isBlocked };
export type { IncompleteUserSchema, UserSchema, ApprovalStatus };
