import { Button } from "@/common/components/button/button";
import Search from "@icons/search.svg?url";
import Cross from "@icons/cross.svg?react";
import { Spinner } from "@/common/components/spinner/spinner";
import { useQuery } from "@tanstack/react-query";
import { usePurchasePricesParams } from "../purchase-prices";
import { postalCodesQuery } from "../purchase-prices.api";
import { useClickAway } from "@/common/hooks/use-click-away";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import invariant from "tiny-invariant";
import React from "react";
import { Trans, t } from "@lingui/macro";
import { InputField } from "@/common/components/form/input-field/input-field";

type Fields = {
  postalCode: string;
};

const PostalCodeDialog = () => {
  const { params, closeDialog, setPostalCode } = usePurchasePricesParams();
  const postalCodes = useQuery(postalCodesQuery());
  const clickAwayRef = useClickAway<HTMLDivElement>(closeDialog);
  const postalCodeObject = postalCodes.data?.find(
    (postalCode) => postalCode.code === params?.postalCode,
  );
  const form = useForm<Fields>({
    defaultValues: {
      postalCode: postalCodeObject?.label ?? "",
    },
  });
  const postalCodeField = form.register("postalCode");
  const postalCodeValue = form.watch("postalCode");
  const [suggestionsVisible, setSuggestionsVisible] = React.useState(false);
  const suggestions =
    postalCodeValue.length > 0
      ? (postalCodes.data ?? []).filter((c) =>
          c.label.toLowerCase().includes(postalCodeValue.toLowerCase()),
        )
      : [];
  const handleSubmit: SubmitHandler<Fields> = () => {
    const nextPostalCodeObject = (postalCodes.data ?? []).find(
      (postalCode) => postalCode.label === postalCodeValue,
    );

    if (!nextPostalCodeObject) {
      form.setError("postalCode", {
        message: t`Vyberte PSČ ze seznamu`,
      });

      return;
    }

    const postalCode = postalCodeValue.split(" ").at(0);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(postalCode, "postalCode is required");
    setPostalCode(postalCode);
  };

  const handleSelectPostalCode = (code: string) => {
    form.setValue("postalCode", code);
    setSuggestionsVisible(false);
  };

  return (
    <>
      <div className="absolute inset-0 z-10 ml-[72px] bg-[rgba(97,97,97,0.60)] backdrop-blur" />
      {postalCodes.status === "pending" ? <Spinner /> : null}
      {postalCodes.status === "error" ? (
        <p>
          <Trans>Nemůžeme načíst seznam PSČ.</Trans>
        </p>
      ) : null}
      {postalCodes.status === "success" ? (
        <div className="fixed inset-0 z-[100] sm:z-20  sm:m-auto sm:h-[350px] sm:w-[400px] sm:pl-10">
          <div className="sm:relative sm:h-fit sm:max-w-40 sm:self-start">
            <div className="overflow-hidden" ref={clickAwayRef}>
              <FormProvider {...form}>
                <form
                  onSubmit={form.handleSubmit(handleSubmit)}
                  className="flex h-[100dvh] shrink-0 flex-col gap-y-6 overflow-y-auto bg-white px-6 pb-8 sm:fixed sm:m-auto sm:h-fit sm:w-fit sm:max-w-[400px] sm:rounded-2xl"
                >
                  <button
                    className="mt-6 self-start md:hidden"
                    onClick={closeDialog}
                  >
                    <Cross />
                  </button>
                  <h1 className="text-2xl font-bold text-can-forest-teal md:mt-6 md:text-center">
                    <Trans>Pro výpočet cen vyplňte PSČ</Trans>
                  </h1>
                  <p className="text-can-slate-blue-gray md:text-center">
                    <Trans>
                      Pro výpočet ceny vyplňte do pole níže poštovní směrovací
                      číslo umístění skladu (PSČ).
                    </Trans>
                  </p>
                  <InputField<Fields>
                    autoComplete="off"
                    placeholder={t`Vyhledat`}
                    label={<Trans>Vaše PSČ</Trans>}
                    name="postalCode"
                    options={{
                      required: t`Vyplňte prosím PSČ`,
                    }}
                    style={{
                      // eslint-disable-next-line lingui/no-unlocalized-strings
                      background: `url(${Search}) right 16px center no-repeat`,
                    }}
                    onChange={(e) => {
                      postalCodeField.onChange(e);
                      setSuggestionsVisible(true);
                    }}
                    onFocus={() => {
                      if (suggestions.length > 0) {
                        setSuggestionsVisible(true);
                      }
                    }}
                  />
                  <Button className="mt-auto" variant="primary" type="submit">
                    <Trans>Zobrazit ceny</Trans>
                  </Button>
                </form>
              </FormProvider>
              {suggestionsVisible && suggestions.length > 0 ? (
                <ul className="absolute left-6 right-6 top-[300px] flex max-h-[140px] w-[calc(100%-theme(spacing.12))] flex-col overflow-y-auto rounded-lg bg-white text-can-slate-blue-gray shadow-can-light-box sm:top-[250px] sm:w-[352px]">
                  {suggestions.map((match) => (
                    <li
                      key={match.label}
                      className="flex border-b border-b-can-silver-cloud"
                    >
                      <button
                        className="flex-grow px-4 py-2 text-left"
                        onClick={() => handleSelectPostalCode(match.label)}
                      >
                        {match.label}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { PostalCodeDialog };
