/**
 * Used to pass client into orval config.
 * Sadly it seems impossible to reuse a single function with baseURL parameter at the moment.
 */

import { getEnvironmentVariable } from "../common/services/env";
import { Args, fetchRetryJson } from "./fetch-retry";

const pricesURL = getEnvironmentVariable("PRICES_API_URL");
const newsURL = getEnvironmentVariable("NEWS_API_URL");
const usersURL = getEnvironmentVariable("USERS_API_URL");

export const pricesInstance = async <T>({
  url,
  method,
  params,
  headers = {},
  data,
}: Args): Promise<T> => {
  return await fetchRetryJson({
    // @ts-expect-error Record<string,string> is expected
    url: `${pricesURL}${url}?` + new URLSearchParams(params),
    method,
    headers,
    data,
  });
};

export const newsInstance = async <T>({
  url,
  method,
  params,
  headers = {},
  data,
}: Args): Promise<T> => {
  return await fetchRetryJson({
    // @ts-expect-error Record<string,string> is expected
    url: `${newsURL}${url}?` + new URLSearchParams(params),
    method,
    headers,
    data,
  });
};

export const usersInstance = async <T>({
  url,
  method,
  params,
  headers = {},
  data,
}: Args): Promise<T> => {
  return await fetchRetryJson({
    // @ts-expect-error Record<string,string> is expected
    url: `${usersURL}${url}?` + new URLSearchParams(params),
    method,
    headers,
    data,
  });
};

export type { Args };
