import { countryCodeToFlag } from "@/common/utils/unicode";
import {
  CompanySimpleUserToApprovalState,
  getUsers,
  setUserState,
} from "@/generated/api/users";
import { queryOptions, useMutation } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { DateTime } from "luxon";

const pendingUsersQuery = () =>
  queryOptions({
    queryKey: ["get-users"],
    queryFn: async () => {
      const users = await getUsers({
        state: ["WAITING"],
      });
      return orderBy(
        users.map(
          ({
            createdAt,
            companyName,
            companyNumber,
            companyId,
            approvalState,
            companyCountry,
            userEmail,
            ...user
          }) => ({
            ...user,
            approvalStatus: approvalState,
            createdAt: DateTime.fromFormat(createdAt, "yyyy-MM-dd"),
            email: userEmail,
            company: {
              country: companyCountry ? countryCodeToFlag(companyCountry) : "",
              name: companyName,
              number: companyNumber,
              id: companyId,
            },
          }),
        ),
        (u) => [u.createdAt, u.email],
        ["desc", "asc"],
      );
    },
    staleTime: Infinity,
  });

const useUserStateMutation = () =>
  useMutation({
    mutationKey: ["set-user-state"],
    mutationFn: ({
      userId,
      state,
    }: {
      userId: number;
      state: CompanySimpleUserToApprovalState;
    }) => setUserState(userId, state),
  });

export { pendingUsersQuery, useUserStateMutation };
