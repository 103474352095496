import { Helmet } from "react-helmet";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getAbsolutePath } from "../routes";

const Root = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <Helmet>
        {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
        <title>Clever°Analytics&apos;</title>
      </Helmet>
      <Outlet />
      {children}
    </>
  );
};

const NavigateToInitialPage = ({ page }: { page: string }) => {
  const { pathname } = useLocation();

  if (pathname === "/") {
    return <Navigate to={getAbsolutePath(page)} replace />;
  }

  return null;
};

export { Root, NavigateToInitialPage };
