/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Clever Analytics Prices Public API
 * OpenAPI spec version: 1.0.0
 */
import { pricesInstance } from '../../api/client';
export type GetCommodityCurrentType = typeof GetCommodityCurrentType[keyof typeof GetCommodityCurrentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCommodityCurrentType = {
  BID: 'BID',
  SETTLE: 'SETTLE',
} as const;

export type GetCommodityCurrentCurrency = typeof GetCommodityCurrentCurrency[keyof typeof GetCommodityCurrentCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCommodityCurrentCurrency = {
  CZK: 'CZK',
  EUR: 'EUR',
  USD: 'USD',
} as const;

export type GetCommodityCurrentParams = {
currency: GetCommodityCurrentCurrency;
type: GetCommodityCurrentType;
'date-from'?: string;
'date-to'?: string;
};

export type GetCommodityRatesType = typeof GetCommodityRatesType[keyof typeof GetCommodityRatesType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCommodityRatesType = {
  BID: 'BID',
  SETTLE: 'SETTLE',
} as const;

export type GetCommodityRatesCurrency = typeof GetCommodityRatesCurrency[keyof typeof GetCommodityRatesCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCommodityRatesCurrency = {
  CZK: 'CZK',
  EUR: 'EUR',
  USD: 'USD',
} as const;

export type GetCommodityRatesPeriod = typeof GetCommodityRatesPeriod[keyof typeof GetCommodityRatesPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCommodityRatesPeriod = {
  CURRENT: 'CURRENT',
  NEXT: 'NEXT',
} as const;

export type GetCommodityRatesParams = {
period: GetCommodityRatesPeriod;
currency: GetCommodityRatesCurrency;
type: GetCommodityRatesType;
'date-from'?: string;
'date-to'?: string;
};

export type GetRateParams = {
'date-from'?: string;
'date-to'?: string;
};

export type CommodityCurrentToQuarter = typeof CommodityCurrentToQuarter[keyof typeof CommodityCurrentToQuarter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommodityCurrentToQuarter = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
} as const;

export type CommodityCurrentToPeriod = typeof CommodityCurrentToPeriod[keyof typeof CommodityCurrentToPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommodityCurrentToPeriod = {
  CURRENT: 'CURRENT',
  NEXT: 'NEXT',
} as const;

export type CommodityCurrentToMonth = typeof CommodityCurrentToMonth[keyof typeof CommodityCurrentToMonth];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommodityCurrentToMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export interface CommodityCurrentTo {
  diff?: number;
  month: CommodityCurrentToMonth;
  period?: CommodityCurrentToPeriod;
  quarter: CommodityCurrentToQuarter;
  rate: number;
  year: number;
}

export type CommodityRatesToType = typeof CommodityRatesToType[keyof typeof CommodityRatesToType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommodityRatesToType = {
  BID: 'BID',
  SETTLE: 'SETTLE',
} as const;

export type CommodityRatesToPeriod = typeof CommodityRatesToPeriod[keyof typeof CommodityRatesToPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommodityRatesToPeriod = {
  CURRENT: 'CURRENT',
  NEXT: 'NEXT',
} as const;

export type CommodityRatesToCurrency = typeof CommodityRatesToCurrency[keyof typeof CommodityRatesToCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommodityRatesToCurrency = {
  CZK: 'CZK',
  EUR: 'EUR',
  USD: 'USD',
} as const;

export type CommodityRatesToCommodity = typeof CommodityRatesToCommodity[keyof typeof CommodityRatesToCommodity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommodityRatesToCommodity = {
  WHEAT: 'WHEAT',
  RAPESEED: 'RAPESEED',
  CRUDE_OIL: 'CRUDE_OIL',
} as const;

export interface CommodityRateTo {
  datetime: string;
  rate?: number;
}

export interface CommodityRatesTo {
  commodity: CommodityRatesToCommodity;
  currency: CommodityRatesToCurrency;
  diff?: number;
  period: CommodityRatesToPeriod;
  rates: CommodityRateTo[];
  type: CommodityRatesToType;
}

export type CurrencyRatesToService = typeof CurrencyRatesToService[keyof typeof CurrencyRatesToService];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrencyRatesToService = {
  ECB: 'ECB',
  CNB: 'CNB',
} as const;

export type CurrencyRatesToCurrency2 = typeof CurrencyRatesToCurrency2[keyof typeof CurrencyRatesToCurrency2];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrencyRatesToCurrency2 = {
  CZK: 'CZK',
  EUR: 'EUR',
  USD: 'USD',
} as const;

export type CurrencyRatesToCurrency1 = typeof CurrencyRatesToCurrency1[keyof typeof CurrencyRatesToCurrency1];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrencyRatesToCurrency1 = {
  CZK: 'CZK',
  EUR: 'EUR',
  USD: 'USD',
} as const;

export interface CurrencyRateTo {
  date: string;
  rate: number;
}

export interface CurrencyRatesTo {
  currency1: CurrencyRatesToCurrency1;
  currency2: CurrencyRatesToCurrency2;
  rates: CurrencyRateTo[];
  service: CurrencyRatesToService;
}





  /**
 * @summary Get historic currency prices
 */
export const getRate = (
    currency1: 'CZK' | 'EUR' | 'USD',
    currency2: 'CZK' | 'EUR' | 'USD',
    params?: GetRateParams,
 ) => {
      return pricesInstance<CurrencyRatesTo>(
      {url: `/can-prices/api/currency/rate/${currency1}/${currency2}`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Get historic commodity prices
 */
export const getCommodityRates = (
    id: 'WHEAT' | 'RAPESEED' | 'CRUDE_OIL',
    params: GetCommodityRatesParams,
 ) => {
      return pricesInstance<CommodityRatesTo>(
      {url: `/can-prices/api/commodity/rate/${id}`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @deprecated
 * @summary Load historic prices from matif
 */
export const loadPrices = (
    
 ) => {
      return pricesInstance<void>(
      {url: `/can-prices/api/commodity/rate/load`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Get current commodity prices
 */
export const getCommodityCurrent = (
    id: 'WHEAT' | 'RAPESEED' | 'CRUDE_OIL',
    params: GetCommodityCurrentParams,
 ) => {
      return pricesInstance<CommodityCurrentTo[]>(
      {url: `/can-prices/api/commodity/current/${id}`, method: 'GET',
        params
    },
      );
    }
  
export type GetRateResult = NonNullable<Awaited<ReturnType<typeof getRate>>>
export type GetCommodityRatesResult = NonNullable<Awaited<ReturnType<typeof getCommodityRates>>>
export type LoadPricesResult = NonNullable<Awaited<ReturnType<typeof loadPrices>>>
export type GetCommodityCurrentResult = NonNullable<Awaited<ReturnType<typeof getCommodityCurrent>>>
