/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Clever Analytics News Public API
 * OpenAPI spec version: 1.0.0
 */
import { newsInstance } from '../../api/client';
import { imageUpload } from '../../api/image-upload';
export type CreateBody = {
  file: Blob;
};

export type GetReportsSortOrder = typeof GetReportsSortOrder[keyof typeof GetReportsSortOrder];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReportsSortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type GetReportsParams = {
'date-from'?: string;
'date-to'?: string;
'sort-order': GetReportsSortOrder;
};

export interface LanguageTo {
  id: string;
  localName: string;
  name: string;
}

export interface ErrorResponse {
  errors?: string[];
  message?: string;
  status: string;
  timestamp: number;
}

export type ReportListReportToStatus = typeof ReportListReportToStatus[keyof typeof ReportListReportToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportListReportToStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const;

export interface ReportListReportTo {
  dateFrom: string;
  dateTo: string;
  headline: string;
  id: number;
  image?: ImageTo;
  language: string;
  status: ReportListReportToStatus;
  summary?: string;
}

export interface ReportListTo {
  first?: string;
  last?: string;
  reports: ReportListReportTo[];
}

export type ReportPatchToStatus = typeof ReportPatchToStatus[keyof typeof ReportPatchToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportPatchToStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const;

export interface ReportPatchTo {
  status?: ReportPatchToStatus;
}

export interface ReportCreateTo {
  commodities: ReportCreateCommodityTo[];
  dateFrom: string;
  dateTo: string;
  headline: string;
  imageId?: string;
  language: string;
  summary?: string;
}

export type ReportToStatus = typeof ReportToStatus[keyof typeof ReportToStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportToStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const;

export type ReportCommodityToRecommendation = typeof ReportCommodityToRecommendation[keyof typeof ReportCommodityToRecommendation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportCommodityToRecommendation = {
  BULLISH: 'BULLISH',
  BEARISH: 'BEARISH',
  NORMAL: 'NORMAL',
} as const;

export interface ReportCommodityTo {
  commodity: CommodityTo;
  headline: string;
  recommendation: ReportCommodityToRecommendation;
  recommendationText: string;
  summary: string;
}

export interface ImageTo {
  guid: string;
  url: string;
}

export interface ReportTo {
  commodities: ReportCommodityTo[];
  dateFrom: string;
  dateTo: string;
  headline: string;
  id: number;
  image?: ImageTo;
  language: string;
  status: ReportToStatus;
  summary?: string;
}

export interface CommodityTo {
  icon: string;
  id: number;
  name: string;
}

export type ReportCreateCommodityToRecommendation = typeof ReportCreateCommodityToRecommendation[keyof typeof ReportCreateCommodityToRecommendation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportCreateCommodityToRecommendation = {
  BULLISH: 'BULLISH',
  BEARISH: 'BEARISH',
  NORMAL: 'NORMAL',
} as const;

export interface ReportCreateCommodityTo {
  commodityId: number;
  headline: string;
  recommendation: ReportCreateCommodityToRecommendation;
  recommendationText: string;
  summary: string;
}

export interface ReportUpdateTo {
  commodities: ReportCreateCommodityTo[];
  dateFrom: string;
  dateTo: string;
  headline: string;
  imageId?: string;
  language: string;
  summary?: string;
}





  /**
 * @summary Report detail
 */
export const getReport = (
    id: number,
 ) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Update report attributes
 */
export const putReport = (
    id: number,
    reportUpdateTo: ReportUpdateTo,
 ) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: reportUpdateTo
    },
      );
    }
  
/**
 * @summary Delete report
 */
export const deleteReport = (
    id: number,
 ) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'DELETE'
    },
      );
    }
  
/**
 * @summary Set report attributes
 */
export const patchReport = (
    id: number,
    reportPatchTo: ReportPatchTo,
 ) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: reportPatchTo
    },
      );
    }
  
/**
 * @summary List of all reports for provided date range
 */
export const getReports = (
    params: GetReportsParams,
 ) => {
      return newsInstance<ReportListTo>(
      {url: `/can-news/api/report`, method: 'GET',
        params
    },
      );
    }
  
/**
 * @summary Create a new report
 */
export const createReport = (
    reportCreateTo: ReportCreateTo,
 ) => {
      return newsInstance<ReportTo>(
      {url: `/can-news/api/report`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: reportCreateTo
    },
      );
    }
  
/**
 * @summary Upload image
 */
export const create = (
    createBody: CreateBody,
 ) => {
      return imageUpload<ImageTo>(
      {url: `/can-news/api/image`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBody
    },
      );
    }
  
/**
 * @summary List of all available languages
 */
export const getLanguages = (
    
 ) => {
      return newsInstance<LanguageTo[]>(
      {url: `/can-news/api/language`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Language detail
 */
export const getLanguage = (
    id: string,
 ) => {
      return newsInstance<LanguageTo>(
      {url: `/can-news/api/language/${id}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Image detail
 */
export const getImage = (
    id: string,
 ) => {
      return newsInstance<ImageTo>(
      {url: `/can-news/api/image/${id}`, method: 'GET'
    },
      );
    }
  
/**
 * @summary List of all available commodities
 */
export const getCommodities = (
    
 ) => {
      return newsInstance<CommodityTo[]>(
      {url: `/can-news/api/commodity`, method: 'GET'
    },
      );
    }
  
/**
 * @summary Commodity detail
 */
export const getCommodity = (
    id: number,
 ) => {
      return newsInstance<CommodityTo>(
      {url: `/can-news/api/commodity/${id}`, method: 'GET'
    },
      );
    }
  
export type GetReportResult = NonNullable<Awaited<ReturnType<typeof getReport>>>
export type PutReportResult = NonNullable<Awaited<ReturnType<typeof putReport>>>
export type DeleteReportResult = NonNullable<Awaited<ReturnType<typeof deleteReport>>>
export type PatchReportResult = NonNullable<Awaited<ReturnType<typeof patchReport>>>
export type GetReportsResult = NonNullable<Awaited<ReturnType<typeof getReports>>>
export type CreateReportResult = NonNullable<Awaited<ReturnType<typeof createReport>>>
export type CreateResult = NonNullable<Awaited<ReturnType<typeof create>>>
export type GetLanguagesResult = NonNullable<Awaited<ReturnType<typeof getLanguages>>>
export type GetLanguageResult = NonNullable<Awaited<ReturnType<typeof getLanguage>>>
export type GetImageResult = NonNullable<Awaited<ReturnType<typeof getImage>>>
export type GetCommoditiesResult = NonNullable<Awaited<ReturnType<typeof getCommodities>>>
export type GetCommodityResult = NonNullable<Awaited<ReturnType<typeof getCommodity>>>
