import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const currencies = ["CZK", "EUR"] as const;
type Currency = (typeof currencies)[number];

const useCurrency = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currencyEnum = z.enum(currencies);
  const currency = currencyEnum.parse(searchParams.get("currency"));

  const setCurrency = (next: Currency) => {
    setSearchParams((cur) => {
      cur.set("currency", next);
      return cur;
    });
  };

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setCurrency(e.target.value as Currency);
  };

  const Component = currency ? (
    <select
      onChange={handleChange}
      defaultValue={currency}
      className="-mr-2 border-0 bg-transparent text-can-slate-blue-gray"
    >
      {currencies.map((currency) => (
        <option key={currency} value={currency}>
          {currency}
        </option>
      ))}
    </select>
  ) : null;

  return [Component, currency, setCurrency] as const;
};

export { useCurrency, currencies };
