import { useParams } from "react-router-dom";
import newspaperSvg from "@icons/newspaper.svg";
import angleSmallDownSvg from "@icons/angle-small-down.svg";
import bearishSvg from "@icons/bearish.svg";
import bullishSvg from "@icons/bullish.svg";
import equalSvg from "@icons/equal.svg";
import Cross from "@icons/cross.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { Metadata } from "@/common/components/metadata/metadata";
import { getAbsolutePath } from "../routes";
import { path as reportsPath } from "@/routes/reports/reports";
import React from "react";
import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { useQuery } from "@tanstack/react-query";
import { reportQuery } from "./report.api";
import invariant from "tiny-invariant";
import { DateTime } from "luxon";
import { ReportCommodityToRecommendation } from "@/generated/api/news";
import { FullscreenModalLayout } from "../layout";
import { useIsMobile } from "@/common/hooks/use-media-query";
import { Trans, t } from "@lingui/macro";
import { setupI18n } from "@lingui/core";
import { Failed } from "@/common/components/info/info";
import { useLingui } from "@lingui/react";
import { I18nProvider } from "@/common/providers/i18n-provider";

const myI18n = setupI18n();

const path = ":reportId";
const Report = () => {
  const isMobile = useIsMobile();
  const { reportId } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(reportId, "Report id is missing.");
  const report = useQuery({
    ...reportQuery(parseInt(reportId)),
  });

  let ReportJsx = <Skeleton />;

  if (report.status === "error") {
    ReportJsx = (
      <>
        <Spacer className="h-6" />
        <Failed error={report.error} />
      </>
    );
  }

  if (report.status === "success") {
    const _dateFrom = DateTime.fromISO(report.data.dateFrom).toJSDate();
    const _dateTo = DateTime.fromISO(report.data.dateTo).toJSDate();
    const dateRangeFormatter = new Intl.DateTimeFormat();
    ReportJsx = (
      <>
        <Metadata title={report.data.headline} />
        <Spacer className="h-6" />
        <h1 className="text-2xl font-black text-can-forest-teal">
          {report.data.headline}
        </h1>
        <Spacer className="h-6" />
        {report.data.image ? (
          <img
            src={report.data.image.url}
            className="rounded-lg border border-can-silver-cloud"
          />
        ) : null}
        <Spacer className="h-6" />
        <span className="text-can-midnight-steel">
          {dateRangeFormatter.formatRange(_dateFrom, _dateTo)}
        </span>
        <Spacer className="h-4" />
        <p className="text-can-midnight-steel">{report.data.summary}</p>
        {report.data.commodities.map((commodity) => (
          <React.Fragment key={commodity.commodity.id}>
            <Spacer className="h-4" />
            <details open={!isMobile} className="group text-can-stormy-sky">
              <summary className="mb-6 list-none">
                <div className="flex gap-x-3">
                  <img className="h-6 w-6" src={commodity.commodity.icon} />
                  <h2 className="text-xl font-bold text-can-forest-teal">
                    {commodity.commodity.name}
                  </h2>
                  <img
                    src={angleSmallDownSvg}
                    className="ml-auto rotate-180 group-open:rotate-0"
                  />
                </div>
              </summary>
              <div className="flex flex-col gap-y-4">
                <b className="text-can-midnight-steel">{commodity.headline}</b>
                <div
                  className="can-auto-tailwindcss flex flex-col gap-3"
                  dangerouslySetInnerHTML={{ __html: commodity.summary }}
                />
                <div
                  className="can-auto-tailwindcss flex flex-col gap-3"
                  dangerouslySetInnerHTML={{
                    __html: commodity.recommendationText,
                  }}
                />
              </div>
              <I18nProvider language={report.data.language} i18n={myI18n}>
                <div className="mt-6 flex flex-col gap-y-2">
                  <h3 className="font-bold text-can-forest-teal">
                    <Trans>Výhled</Trans>
                  </h3>
                  <Recommendation value={commodity.recommendation} />
                </div>
              </I18nProvider>
            </details>
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <FullscreenModalLayout
      headerButton={
        <SearchParamsLink to={getAbsolutePath(reportsPath)} replace>
          <Cross />
        </SearchParamsLink>
      }
    >
      {ReportJsx}
    </FullscreenModalLayout>
  );
};

const Recommendation = ({
  value,
}: {
  value: keyof typeof ReportCommodityToRecommendation;
}) => {
  const { i18n } = useLingui();
  let text = "";
  const icon = {
    src: "",
    className: "",
  };
  switch (value) {
    case "NORMAL":
      text = t(i18n)`Neočekává se růst ani pokles cen`;
      icon.src = equalSvg;
      // eslint-disable-next-line lingui/no-unlocalized-strings
      icon.className = "h-4 w-4";
      break;
    case "BEARISH":
      text = t(i18n)`Bearish - očekává se pokles cen`;
      icon.src = bearishSvg;
      break;
    case "BULLISH":
      text = t(i18n)`Bullish - očekává se růst cen`;
      icon.src = bullishSvg;
      break;
  }

  return (
    <span className="flex items-center gap-2 rounded-2xl bg-can-silver-gray px-4 py-3">
      <img src={icon.src} className={icon.className} alt={text} />
      <b className="mx-auto font-bold text-can-midnight-steel">{text}</b>
    </span>
  );
};

const Skeleton = () => {
  return (
    <div role="status" className="mx-6 flex flex-col">
      <Spacer className="h-6" />
      <h1 className="h-5 shrink-0 animate-pulse rounded-full bg-gray-200">
        &nbsp;
      </h1>
      <Spacer className="h-6" />
      <div className="flex h-52 w-full animate-pulse items-center justify-center rounded bg-gray-200">
        <img
          className="h-24 animate-pulse opacity-50 grayscale"
          src={newspaperSvg}
        />
      </div>
      <Spacer className="h-6" />
      {Array.from({ length: 5 }).map((_, i) => {
        return (
          <React.Fragment key={i}>
            <div className="h-3 shrink-0 animate-pulse rounded-full bg-gray-200" />
            <Spacer className="h-8" />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export { Report, path };
