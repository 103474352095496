import { Navigate, Outlet, useLocation } from "react-router-dom";
import { EditorProvider, path as editorPath } from "./admin/editor/editor";
import { Layout as AdminLayout } from "./admin/components/layout/layout";
import {
  AdminGuard,
  PermissionGuard,
  useHasPermission,
} from "@/common/acl/guard/guard";
import * as PendingUsers from "./admin/pending-users/pending-users";
import * as Companies from "./admin/companies/companies";
import * as User from "./admin/user/user";
import * as Company from "./admin/company/company";
import { Loader } from "./admin/components/loader/loader";
import { ErrorBoundary } from "react-error-boundary";
import { Failed } from "@/common/components/info/info";
import { Suspense } from "react";
import { Spinner } from "@/common/components/spinner/spinner";

const routes = () => [
  {
    path: "admin",
    element: <RedirectToAvailableScreen />,
    children: [
      {
        element: <AdminGuard />,
        children: [
          {
            path: "",
            element: <AdminLayout />,
            children: [
              {
                path: editorPath,
                element: (
                  <PermissionGuard
                    permission="news:report:manage"
                    onDenied={<Navigate to=".." />}
                  >
                    <EditorProvider />
                  </PermissionGuard>
                ),
              },
              {
                path: `${editorPath}/:id`,
                element: (
                  <PermissionGuard
                    permission="news:report:manage"
                    onDenied={<Navigate to=".." />}
                  >
                    <EditorProvider />
                  </PermissionGuard>
                ),
              },
              {
                path: Companies.path,
                element: (
                  <PermissionGuard
                    permission="users:company:list"
                    onDenied={<Navigate to=".." />}
                  >
                    <ErrorBoundary
                      fallbackRender={({ error }) => <Failed error={error} />}
                    >
                      <Suspense fallback={<Spinner />}>
                        <Companies.Companies />
                      </Suspense>
                    </ErrorBoundary>
                  </PermissionGuard>
                ),
                children: [
                  {
                    path: ":id",
                    element: (
                      <Loader>
                        <Company.Company>
                          <Company.Subscription />
                          <Company.Detail />
                          <Company.Users />
                        </Company.Company>
                      </Loader>
                    ),
                  },
                ],
              },
              {
                path: PendingUsers.path,
                element: (
                  <PermissionGuard
                    permission="users:company:list"
                    onDenied={<Navigate to=".." />}
                  >
                    <PendingUsers.Users />
                  </PermissionGuard>
                ),
                children: [
                  {
                    path: User.path,
                    element: (
                      <Loader>
                        <User.User />
                      </Loader>
                    ),
                  },
                  {
                    path: Company.path,
                    element: (
                      <Loader>
                        <Company.Company />
                      </Loader>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const RedirectToAvailableScreen = () => {
  const { pathname } = useLocation();
  const canEditNews = useHasPermission("news:report:manage");
  const canManageUsers = useHasPermission("users:company:list");

  if ([canEditNews, canManageUsers].some((permission) => permission === null)) {
    return null;
  }

  if (pathname === "/admin" || pathname === "/admin/") {
    let target: string | undefined;

    if (canManageUsers) {
      target = PendingUsers.path;
    }

    if (canEditNews) {
      target = editorPath;
    }

    if (!target) {
      return <Navigate to="/" />;
    }

    return <Navigate to={target} replace />;
  }

  return <Outlet />;
};

export { routes };
