import * as Report from "./report/report";
import { Reports, ReportsParams, path as reportsPath } from "./reports/reports";
import {
  StockExchange,
  StockExchangeParams,
  path as stockExchangePath,
} from "./stock-exchange/stock-exchange";
import { ErrorPage } from "@/common/components/error-page/error-page";

const routes = () => [
  {
    path: reportsPath,
    element: (
      <ReportsParams>
        <Reports />
      </ReportsParams>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: Report.path,
        element: <Report.Report />,
      },
    ],
  },
  {
    path: stockExchangePath,
    element: (
      <StockExchangeParams>
        <StockExchange />
      </StockExchangeParams>
    ),
  },
];

export { routes };
