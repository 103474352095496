/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query CropPricesRegion(\n    $latitude: Float!\n    $longitude: Float!\n    $currency: Currency!\n  ) {\n    cropPricesInRegion(gps: { latitude: $latitude, longitude: $longitude }) {\n      id\n      crop {\n        id\n        name\n      }\n      quarter {\n        id\n        name\n      }\n      price(currency: $currency)\n      date\n    }\n  }\n": types.CropPricesRegionDocument,
    "\n  query Offer($id: ID!, $currency: Currency!) {\n    offer(id: $id) {\n      crop {\n        id\n        name\n      }\n      id\n      parameters {\n        edges {\n          node {\n            id\n            ... on SelectOfferParameter {\n              option {\n                id\n                title\n                __typename\n              }\n            }\n            ... on FloatOfferParameter {\n              value\n            }\n            cropParameter {\n              id\n              title\n            }\n          }\n        }\n      }\n      amount\n      totalAmount\n      storage {\n        id\n        label\n        address\n        lastPrices {\n          edges {\n            node {\n              price(currency: $currency)\n            }\n          }\n        }\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.OfferDocument,
    "\n  query Storage($id: ID!) {\n    storage(id: $id) {\n      id\n      label\n      address\n      offers {\n        edges {\n          node {\n            id\n            amount\n            totalAmount\n            crop {\n              id\n              name\n            }\n          }\n        }\n      }\n      company {\n        id\n        name\n      }\n    }\n  }\n": types.StorageDocument,
    "\n  mutation RemoveOrder($id: ID!) {\n    removeOrder(id: $id)\n  }\n": types.RemoveOrderDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CropPricesRegion(\n    $latitude: Float!\n    $longitude: Float!\n    $currency: Currency!\n  ) {\n    cropPricesInRegion(gps: { latitude: $latitude, longitude: $longitude }) {\n      id\n      crop {\n        id\n        name\n      }\n      quarter {\n        id\n        name\n      }\n      price(currency: $currency)\n      date\n    }\n  }\n"): (typeof documents)["\n  query CropPricesRegion(\n    $latitude: Float!\n    $longitude: Float!\n    $currency: Currency!\n  ) {\n    cropPricesInRegion(gps: { latitude: $latitude, longitude: $longitude }) {\n      id\n      crop {\n        id\n        name\n      }\n      quarter {\n        id\n        name\n      }\n      price(currency: $currency)\n      date\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Offer($id: ID!, $currency: Currency!) {\n    offer(id: $id) {\n      crop {\n        id\n        name\n      }\n      id\n      parameters {\n        edges {\n          node {\n            id\n            ... on SelectOfferParameter {\n              option {\n                id\n                title\n                __typename\n              }\n            }\n            ... on FloatOfferParameter {\n              value\n            }\n            cropParameter {\n              id\n              title\n            }\n          }\n        }\n      }\n      amount\n      totalAmount\n      storage {\n        id\n        label\n        address\n        lastPrices {\n          edges {\n            node {\n              price(currency: $currency)\n            }\n          }\n        }\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Offer($id: ID!, $currency: Currency!) {\n    offer(id: $id) {\n      crop {\n        id\n        name\n      }\n      id\n      parameters {\n        edges {\n          node {\n            id\n            ... on SelectOfferParameter {\n              option {\n                id\n                title\n                __typename\n              }\n            }\n            ... on FloatOfferParameter {\n              value\n            }\n            cropParameter {\n              id\n              title\n            }\n          }\n        }\n      }\n      amount\n      totalAmount\n      storage {\n        id\n        label\n        address\n        lastPrices {\n          edges {\n            node {\n              price(currency: $currency)\n            }\n          }\n        }\n        company {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Storage($id: ID!) {\n    storage(id: $id) {\n      id\n      label\n      address\n      offers {\n        edges {\n          node {\n            id\n            amount\n            totalAmount\n            crop {\n              id\n              name\n            }\n          }\n        }\n      }\n      company {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query Storage($id: ID!) {\n    storage(id: $id) {\n      id\n      label\n      address\n      offers {\n        edges {\n          node {\n            id\n            amount\n            totalAmount\n            crop {\n              id\n              name\n            }\n          }\n        }\n      }\n      company {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveOrder($id: ID!) {\n    removeOrder(id: $id)\n  }\n"): (typeof documents)["\n  mutation RemoveOrder($id: ID!) {\n    removeOrder(id: $id)\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;