import { NavLink } from "react-router-dom";
import { path as reportsPath } from "@/routes/reports/reports";
import { getAbsolutePath } from "@/routes/routes";
import { path as stockExchangePath } from "@/routes/stock-exchange/stock-exchange";
import { path as purchasePricesPath } from "@/routes/purchase-prices/purchase-prices";
import NewspaperSvg from "@icons/newspaper.svg?react";
import ChartSvg from "@icons/chart.svg?react";
import UsdCircleSvg from "@icons/usd-circle.svg?react";
import { useFeatureFlag } from "@/common/providers/feature-flag-provider";
import { t } from "@lingui/macro";

type Link = {
  className?: string | ((args: { isActive: boolean }) => string);
  to: string;
  icon: React.ReactNode;
  label: string;
};

const NavBar = ({
  renderItem,
}: {
  renderItem?: (link: Link) => React.ReactNode;
}) => {
  const featureFlag = useFeatureFlag();
  const links: Link[] = [];

  if (featureFlag.get("trading")) {
    links.push({
      className: getClassName,
      label: t`Výkupní ceny`,
      to: getAbsolutePath(purchasePricesPath),
      icon: <UsdCircleSvg />,
    });
  }

  links.push(
    ...([
      {
        className: getClassName,
        label: t`Burzovní informace`,
        to: getAbsolutePath(stockExchangePath),
        icon: <ChartSvg />,
      },
      {
        className: getClassName,
        label: t`Zprávy`,
        to: getAbsolutePath(reportsPath),
        icon: <NewspaperSvg />,
      },
    ] satisfies Link[]),
  );

  return (
    <nav className="flex w-full flex-grow flex-col items-center gap-3 font-bold text-white shadow-can-light-box">
      {links.map(({ label, icon, ...link }) =>
        typeof renderItem === "function" ? (
          renderItem({ ...link, icon, label })
        ) : (
          <NavLink title={label} key={link.to} {...link}>
            {icon}
          </NavLink>
        ),
      )}
    </nav>
  );
};

// eslint-disable-next-line lingui/no-unlocalized-strings
const active = "[&_path]:fill-white bg-[#399E82] text-white";
// eslint-disable-next-line lingui/no-unlocalized-strings
const inactive = "[&_path]:fill-[#85C4B3] text-[#85C4B3]";
const getClassName = ({ isActive }: { isActive: boolean }) =>
  // eslint-disable-next-line lingui/no-unlocalized-strings
  `${isActive ? active : inactive} flex w-full justify-center py-3`;

export { NavBar, active, getClassName };
