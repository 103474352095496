import { sortString } from "@/common/utils/array";
import { getCompany1, getCompanyUsers } from "@/generated/api/users";
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

const companyQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["company", id],
    queryFn: () => getCompany1(id),
    select: ({ planExpiresAt, ...data }) => ({
      ...data,
      planExpiresAt: planExpiresAt
        ? DateTime.fromFormat(planExpiresAt, "yyyy-MM-dd").toLocaleString(
            DateTime.DATE_FULL,
          )
        : "-",
    }),
  });

const companyUsersQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["company", id, "users"],
    queryFn: () => getCompanyUsers(id),
    select: (users) =>
      users.toSorted((a, b) =>
        sortString(a.userEmail ?? "", b.userEmail ?? ""),
      ),
  });

type CompanyUser = Awaited<ReturnType<typeof getCompanyUsers>>[number];

export { companyQuery, companyUsersQuery };
export type { CompanyUser };
