const permissions = [
  "news:report:manage",
  "users:company:approve",
  "users:company:list",
  "users:company:delete",
  "users:user:delete",
] as const;

type Permission = (typeof permissions)[number];

export { permissions };
export type { Permission };
