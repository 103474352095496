import { useAuth0 } from "@auth0/auth0-react";
import Bell from "@icons/bell.svg?react";
import CircleUser from "@icons/circle-user.svg?react";
import User from "@icons/user.svg?react";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { secondary } from "../button/button";
import { useUserSettings } from "@/common/providers/user-settings-provider";

const UserProfile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const userSettings = useUserSettings();

  if (isLoading) {
    return <Trans>Načítá se</Trans>;
  }

  if (!isAuthenticated) {
    // TODO how to handle this?
    return <Trans>neověřený uživatel</Trans>;
  }

  if (user) {
    return (
      <div className="fixed ml-20 hidden w-[calc(100%-theme(spacing.20))] justify-end gap-6 bg-white pb-4 pr-6 pt-6 sm:z-[1] sm:flex">
        <Bell className="h-6 w-6" />
        <div data-testid="user-profile" className="group relative flex gap-2">
          <CircleUser className="h-6 w-6" />
          <b className="font-can-inter font-bold text-can-forest-teal">
            {user.name}
          </b>
          <div className="invisible absolute w-full group-hover:visible">
            <ul className="mt-10 flex flex-col gap-y-6 rounded-2xl border bg-white pb-8 font-bold text-can-forest-teal shadow-can-light-box">
              <li>
                <button
                  onClick={() =>
                    userSettings.dispatch({ type: "open", screen: "profile" })
                  }
                  className="flex w-full gap-x-4 p-6 first:rounded-t-2xl hover:bg-can-silver-gray"
                >
                  <User />
                  <Trans>Profil</Trans>
                </button>
              </li>
              <li className="mx-auto flex w-[80%] only:pt-10">
                <Link className={`${secondary} basis-full`} to="/logout">
                  <Trans>Odhlásit se</Trans>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export { UserProfile };
