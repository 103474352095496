import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCompanyRelationMutation,
  useWipeOutUserMutation,
  userQuery,
} from "./user.api";
import invariant from "tiny-invariant";
import { Trans, t } from "@lingui/macro";
import Cross from "@icons/cross.svg?react";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { Metadata } from "@/common/components/metadata/metadata";
import { Dialog } from "@/common/components/dialog/dialog";
import React from "react";
import { PermissionGuard, useHasPermission } from "@/common/acl/guard/guard";

const path = ":id";

type Dialog = "user" | "company-relation";

const User = () => {
  const { id } = useParams();
  const canDeleteCompanyRelation = useHasPermission("users:company:delete");
  const canDeleteUser = useHasPermission("users:user:delete");
  useScrollbarToggle(!!id);
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "user id is missing");
  const user = useSuspenseQuery(userQuery({ id: parseInt(id) }));
  const deleteCompanyRelation = useDeleteCompanyRelationMutation();
  const wipeOutUser = useWipeOutUserMutation();
  const [dialog, setDialog] = React.useState<Dialog | undefined>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const rejectRef = React.useRef<() => void>();
  const dialogContent: Record<Dialog, React.ReactNode> = {
    user: (
      <Trans>
        Tímto zásahem smažete vazbu mezi uživatelem {user.data.userName} a{" "}
        <b>všemi společnostmi</b>, ke kterým je přiřazen.
        <br />
        Rovněž odstraníte všechna metadata, která o uživateli vedeme.
      </Trans>
    ),
    "company-relation": (
      <Trans>
        Tímto zásahem smažete vazbu mezi uživatelem {user.data.userName} a
        společností {user.data.companyName}.<br />
        Další společnosti, ke kterým uživatel může být přiřazen, nebudou tímto
        zásahem dotčeny.
      </Trans>
    ),
  };
  const resolveRef = React.useRef<(value: unknown) => void>();

  const handleWipeOutUser = () => {
    wipeOutUser.mutate(
      {
        ssoUserId: user.data.userId,
      },
      {
        onSuccess: () => {
          setDialog(undefined);
          navigate("..");
          queryClient.invalidateQueries();
        },
        onError: () => {
          alert(t`Uživatele se nepodařilo smazat`);
        },
      },
    );
  };

  const handleDeleteCompanyRelation = () => {
    deleteCompanyRelation.mutate(
      { userId: parseInt(id) },
      {
        onSuccess: () => {
          setDialog(undefined);
          navigate("..");
          queryClient.invalidateQueries();
        },
        onError: () => {
          alert(t`Uživatele se nepodařilo smazat`);
        },
      },
    );
  };

  const runWhenConfirmed = (dialog: Dialog, callback: () => void) => () => {
    setDialog(dialog);
    new Promise((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    })
      .then(callback)
      .catch(() => {})
      .finally(() => setDialog(undefined));
  };

  return (
    <>
      <Metadata title={user.data.userName} />
      <Link className="sm:hidden" to="..">
        <Cross />
      </Link>
      <h1 className="text-xl font-bold">{user.data.userName}</h1>
      <ul className="flex flex-col gap-y-2">
        <li>
          <Trans>Stav</Trans>
          {": "}
          {user.data.approvalState}
        </li>
        <li>
          <Trans>Telefon</Trans>
          {": "}
          {user.data.userMetadata.common.phone.countryCode}
          {user.data.userMetadata.common.phone.phoneNumber}
        </li>
        <li>
          <Trans>E-mail</Trans>
          {": "}
          {user.data.userEmail}
        </li>
        <li>
          <Trans>Jazyk webu</Trans>
          {": "}
          {user.data.userMetadata.can.settings.language}
        </li>
        <li>
          <Trans>Jazyk aktualit</Trans>
          {": "}
          {user.data.userMetadata.can.settings.news.language}
        </li>
        <details>
          <summary>
            <Trans>Metadata</Trans>
          </summary>
          <pre className="max-h-[150px] overflow-auto">
            {JSON.stringify(user.data.userMetadata, null, "\t")}
          </pre>
        </details>
        {canDeleteUser || canDeleteCompanyRelation ? (
          <details className="text-red-500">
            <summary>
              <Trans>Nebezpečná zóna</Trans>
            </summary>
            <div className="mt-4 flex flex-col gap-y-4">
              <PermissionGuard permission="users:company:delete">
                <button
                  onClick={runWhenConfirmed(
                    "company-relation",
                    handleDeleteCompanyRelation,
                  )}
                  className="rounded-lg border border-red-500 p-2 hover:bg-red-500 hover:text-white"
                >
                  <Trans>Odebrat uživatele ze společnosti</Trans>
                </button>
              </PermissionGuard>
              <PermissionGuard permission="users:user:delete">
                <button
                  onClick={runWhenConfirmed("user", handleWipeOutUser)}
                  className="rounded-lg border border-red-500 p-2 hover:bg-red-500 hover:text-white"
                >
                  <Trans>Odebrat uživatele ze všech společností</Trans>
                </button>
              </PermissionGuard>
            </div>
          </details>
        ) : null}
      </ul>
      {dialog ? (
        <Dialog>
          <Dialog.Title>
            <Trans>Opravdu chcete provést akci?</Trans>
          </Dialog.Title>
          <p className="text-red-500">{dialogContent[dialog]}</p>
          <Dialog.Actions>
            <Dialog.Confirm
              disabled={deleteCompanyRelation.status === "pending"}
              onClick={resolveRef.current}
            >
              <Trans>Ano</Trans>
            </Dialog.Confirm>
            <Dialog.Cancel onClick={rejectRef.current}>
              <Trans>Ne</Trans>
            </Dialog.Cancel>
          </Dialog.Actions>
        </Dialog>
      ) : null}
    </>
  );
};

export { User, path };
