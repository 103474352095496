import { currencyFormatter } from "@/common/services/formatter";

type Props = {
  className?: string;
  prices: {
    value: number;
    currency: string;
    quarter: string;
  }[];
};

const PriceList = ({ prices, className = "" }: Props) => (
  <ul
    className={`bg-can-silver-gray grid grid-cols-4 rounded-2xl border border-can-silver-cloud px-2 !text-[10px] text-can-midnight-steel sm:text-xs ${className}`}
  >
    {prices.map((price) => (
      <li
        key={price.quarter}
        className="flex flex-col flex-wrap gap-y-2 border-r border-can-silver-cloud p-2 [&:nth-child(4)]:border-none"
      >
        <b>
          {price.value
            ? currencyFormatter(price.currency).format(price.value)
            : null}
        </b>
        <span className="text-can-slate-blue-gray">
          {price.quarter.replace(" ", "/")}
        </span>
      </li>
    ))}
  </ul>
);

export { PriceList };
