import { PreviewCard } from "@/common/components/preview-card/preview-card";
import {
  QuickFilters,
  getFilterByDateRange,
  getFilterParams,
  initialDateFilter,
} from "@/common/components/quick-filters/quick-filters";
import {
  useParams,
  useSearchParams,
  createSearchParams,
  useLocation,
  Navigate,
  Link,
} from "react-router-dom";
import React from "react";
import { Metadata } from "@/common/components/metadata/metadata";
import { Spacer } from "@/common/components/spacer/spacer";
import {
  Params,
  reportsDateRangeQuery,
  reportsQuery,
  reportsQueryParamsSchema,
} from "./reports.api";
import { useQuery } from "@tanstack/react-query";
import { SortOrder, initialSortOrder } from "./reports.sort-order";
import {
  MergeSearchParamsLink,
  SearchParamsLink,
} from "@/common/components/search-params-link/search-params-link";
import {
  Filter,
  getAvailableFilters,
  getNextFilter,
  getURLParams,
} from "@/common/components/quick-filters/quick-filters.utils";
import { useFilterClickHandler } from "./reports.hooks";
import { Toolbar } from "./reports.toolbar";
import { path as editorPath } from "@/routes/admin/editor/editor";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { TwoColumnLayout } from "../layout";
import { primary } from "@/common/components/button/button";
import { PageTitle } from "@/common/components/page-title/page-title";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Failed } from "@/common/components/info/info";
import { useHasPermission } from "@/common/acl/guard/guard";

const path = "reports";
const params = createSearchParams({
  ...initialSortOrder,
  ...initialDateFilter,
});
const pathWithParams = `${path}?${params}`;

const Reports = () => {
  const { _ } = useLingui();
  const params = useParams();
  const isDetailOpen = params.reportId !== undefined;
  useScrollbarToggle(isDetailOpen);
  const isReportsManager = useHasPermission("news:report:manage");
  const previousReportsCount = React.useRef(8); // prevents layout shift on filter change
  const searchParams = useReportSearchParams() as Params;
  const reports = useQuery({
    ...reportsQuery(searchParams),
    enabled: !!searchParams,
  });
  const reportsDateRange = useQuery({
    ...reportsDateRangeQuery(searchParams),
    enabled: !!searchParams,
  });
  const availableFilters = getAvailableFilters(reportsDateRange.data?.oldest);
  const filter = getFilterParams(searchParams);
  const { activeFilter, nextFilter } = getActiveAndNextFilter(
    availableFilters,
    filter,
  );
  const handleFilterClick = useFilterClickHandler(
    availableFilters,
    activeFilter,
  );
  const NextButtonJsx = nextFilter ? (
    <MergeSearchParamsLink
      to={`?${new URLSearchParams(getURLParams(nextFilter))}`}
      className={primary}
    >
      <Trans>Načíst zprávy pro {nextFilter?.label.toLowerCase()}</Trans>
    </MergeSearchParamsLink>
  ) : null;

  let Jsx: React.ReactNode = null;

  if (reports.status === "pending") {
    Jsx = Array.from({ length: previousReportsCount.current }).map((_, i) => (
      <PreviewCard key={i} skeleton />
    ));
  }

  if (reports.status === "error") {
    Jsx = (
      <p className="col-start-1 col-end-[-1] text-can-slate-blue-gray">
        <Failed error={reports.error} />
      </p>
    );
  }

  if (reports.status === "success") {
    previousReportsCount.current = reports.data.length;

    Jsx =
      reports.data.length > 0 ? (
        reports.data.map((report) => (
          <figure
            key={report.id}
            className={`flex w-full flex-col gap-y-2 justify-self-start sm:self-start`}
          >
            <SearchParamsLink to={report.id}>
              <PreviewCard
                {...report}
                className={`${
                  params.reportId === report.id
                    ? "bg-can-silver-gray [&>h2]:text-can-forest-teal"
                    : ""
                } ${report.status === "DRAFT" ? "opacity-50 grayscale" : ""}`}
              />
            </SearchParamsLink>
            {isReportsManager ? (
              <Toolbar id={report.id} status={report.status} />
            ) : null}
          </figure>
        ))
      ) : (
        <p className="col-start-1 col-end-[-1] text-can-slate-blue-gray">
          <Trans>
            Bohužel vám nemáme co ukázat. Zkuste prosím jinou kombinaci filtrů.
          </Trans>
        </p>
      );
  }

  return (
    <TwoColumnLayout
      left={{
        content: (
          <>
            <Metadata title={_(msg`Zprávy`)} />
            {isReportsManager ? (
              <>
                <Link
                  className="self-start text-can-forest-teal underline decoration-can-forest-teal"
                  to={`/admin/${editorPath}`}
                  target="_blank"
                >
                  <Trans>+ Napsat nový článek</Trans>
                </Link>
                <Spacer className="h-6" />
              </>
            ) : null}
            <div className="max-w-[calc(100vw-theme(spacing.12))]">
              <QuickFilters
                onClick={handleFilterClick}
                filters={availableFilters}
                activeFilter={activeFilter}
              />
            </div>
            <section className="grid basis-full grid-cols-1 content-start gap-6 sm:grid-cols-[repeat(auto-fill,_minmax(350px,1fr))]">
              {Jsx}
            </section>
            {nextFilter ? (
              <div className="center flex basis-auto justify-center pb-6">
                {NextButtonJsx}
              </div>
            ) : null}
          </>
        ),
        header: (
          <>
            <PageTitle>
              <Trans>Market news</Trans>
            </PageTitle>
            <SortOrder />
          </>
        ),
      }}
      right={isDetailOpen}
    />
  );
};

const ReportsParams = ({ children }: React.PropsWithChildren) => {
  const searchParams = useReportSearchParams();
  const { pathname } = useLocation();

  if (!searchParams) {
    return <Navigate to={`${pathname}?${params}`} />;
  }

  return <>{children}</>;
};

const useReportSearchParams = () => {
  const [searchParams] = useSearchParams();
  const parsedParams = reportsQueryParamsSchema.safeParse(
    Object.fromEntries(searchParams),
  );

  if (parsedParams.success) {
    return parsedParams.data;
  }
};

const getActiveAndNextFilter = (
  filters: Filter[],
  current: Omit<Filter, "label">,
) => {
  const activeFilter = getFilterByDateRange(filters, current);
  const nextFilter = getNextFilter(filters, current);

  return { activeFilter, nextFilter };
};

export { Reports, ReportsParams, path, pathWithParams, params };
