import { pathWithParams as reportsPathWithParams } from "@/routes/reports/reports";
import { getAbsolutePath } from "@/routes/routes";
import {
  Navigate,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";
import { Logo } from "../logo/logo";
import { Trans } from "@lingui/macro";
import { logger } from "@/common/services/logger";

const ErrorPage = () => {
  const error = useRouteError();

  if (error) {
    logger.error(error);
    return (
      <div className="flex min-h-full items-center justify-center text-can-stormy-sky">
        <div className="flex gap-x-8 rounded border p-8 shadow">
          <Logo />
          <span className="self-start">
            <Trans>
              Je nám líto, ale nedaří se nám získat data.
              <br />
              Zkuste to prosím za chvíli.
            </Trans>
          </span>
        </div>
      </div>
    );
  }

  if (isRouteErrorResponse(error) && error.status === 400) {
    return <Navigate to={`${getAbsolutePath(reportsPathWithParams)}`} />;
  }

  return (
    <div className="flex min-h-full items-center justify-center text-can-stormy-sky">
      <div className="flex gap-x-8 rounded border p-8 shadow">
        <Logo />
        <Trans
          render={({ children }) => (
            <span className="self-start">{children}</span>
          )}
        >
          Je nám líto, ale tady vám pšenka nepokvete.
        </Trans>
      </div>
    </div>
  );
};

export { ErrorPage };
