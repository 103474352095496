import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import invariant from "tiny-invariant";
import { Commodity } from "./editor.commodity";
import { Metadata } from "@/common/components/metadata/metadata";
import {
  commoditiesQuery,
  languagesQuery,
  reportCreateMutation,
  reportQuery,
  reportUpdateMutation,
} from "./editor.api";
import {
  FormValues,
  FormValuesWithImage,
  getInitialValues,
  getReportPayload,
} from "./editor.utils";
import { getAbsolutePath } from "@/routes/routes";
import { path as reportsPath } from "@/routes/reports/reports";
import { Spacer } from "@/common/components/spacer/spacer";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { EditorErrorMessage } from "./editor.error-message";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Spinner } from "@/common/components/spinner/spinner";

const path = "editor";

const EditorProvider = () => {
  const { id } = useParams();
  const isUpdate = id !== undefined;
  const navigate = useNavigate();
  const commodities = useQuery(commoditiesQuery());
  const languages = useQuery(languagesQuery());
  const report = useQuery({
    ...reportQuery(id as string),
    enabled: isUpdate,
  });
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    ...reportCreateMutation(),
    onSuccess: (data) => {
      navigate(`${getAbsolutePath(reportsPath)}/${data.id}`);
    },
    onError: (e) => {
      alert(e.message);
    },
  });
  const updateMutation = useMutation({
    ...reportUpdateMutation(),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries();
      navigate(`${getAbsolutePath(reportsPath)}/${data.id}`);
    },
    onError: (e) => {
      alert(e.message);
    },
  });

  const failed =
    languages.status === "error" ||
    commodities.status === "error" ||
    report.status === "error";
  const loading =
    languages.status === "pending" ||
    commodities.status === "pending" ||
    (report.isLoading && report.status === "pending");

  if (failed) {
    return (
      <p>
        <Trans>Nedaří se stáhnout údaje o zemích nebo komoditách.</Trans>
      </p>
    );
  }

  if (loading) {
    return <Spinner className="mt-4" />;
  }

  const handleSubmit: SubmitHandler<FormValues> = async (values, e) => {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(e, "Form submit event needs to be passed");
    const formValues = new FormData(e.target as HTMLFormElement);
    const cloned = structuredClone(values) as FormValuesWithImage;
    const image = formValues.get("image") as File | undefined;

    cloned.image = image;
    cloned.commodities = cloned.commodities.filter(
      (commodity) => commodity.headline.length > 0,
    );
    const data = await getReportPayload(cloned);
    data.imageId ??= values.imagePreview?.guid;

    if (isUpdate) {
      updateMutation.mutate({ ...data, id: parseInt(id) });
    } else {
      createMutation.mutate(data);
    }
  };

  const initialValues = getInitialValues(report.data, commodities.data);

  return (
    <EditorForm initialValues={initialValues}>
      <Editor
        onSubmit={handleSubmit}
        busy={createMutation.status === "pending"}
      />
    </EditorForm>
  );
};

const EditorForm = ({
  children,
  initialValues,
}: React.PropsWithChildren<{ initialValues: FormValues }>) => {
  const methods = useForm({ defaultValues: initialValues });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

const Editor = ({
  busy,
  onSubmit,
}: {
  busy: boolean;
  onSubmit: (values: FormValues, e: React.BaseSyntheticEvent) => void;
}) => {
  const form = useEditorForm();
  const commodities = useQuery(commoditiesQuery());
  const languages = useQuery(languagesQuery());
  const reportImageRef = React.useRef<HTMLInputElement>(null);
  const reportImagePreviewRef = React.useRef<HTMLDivElement>(null);
  const failed = languages.status === "error" || commodities.status === "error";
  const image = form.watch("imagePreview");
  const { _ } = useLingui();

  const buildPreviewImage = (source: string) => {
    const img = document.createElement("img");
    img.src = source;
    img.classList.add("max-w-40", "aspect-auto");

    return img;
  };

  const clearNode = (node: HTMLElement) => {
    while (node.firstChild && node.lastChild) {
      node.removeChild(node.lastChild);
    }
  };

  React.useEffect(() => {
    if (!image) {
      return;
    }
    const preview = reportImagePreviewRef.current;
    invariant(preview);
    clearNode(preview);

    const img = buildPreviewImage(image.url);
    preview.appendChild(img);
  }, [image]);

  React.useEffect(() => {
    if (import.meta.env.DEV) {
      return;
    }
    const handler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };
    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  const handleImageReset = () => {
    if (reportImageRef.current) {
      reportImageRef.current.value = "";
      form.setValue("imagePreview", undefined);
    }

    if (
      reportImagePreviewRef.current &&
      reportImagePreviewRef.current.firstChild
    ) {
      reportImagePreviewRef.current.removeChild(
        reportImagePreviewRef.current.firstChild,
      );
    }
  };

  const handleImagePreview = () => {
    form.setValue("imagePreview", undefined);
    const preview = reportImagePreviewRef.current;
    invariant(preview);
    invariant(reportImageRef.current);
    const files = reportImageRef.current.files;
    // eslint-disable-next-line lingui/no-unlocalized-strings
    invariant(files, "There should be one file available");

    if (files.length === 0) {
      return;
    }

    const img = buildPreviewImage(URL.createObjectURL(files[0]));

    clearNode(preview);

    preview.appendChild(img);
  };

  if (failed) {
    return <Trans>Nedaří se stáhnout údaje o zemích nebo komoditách.</Trans>;
  }

  if (commodities.status !== "success") {
    return (
      <p>
        <Trans>Načítá se</Trans>
      </p>
    );
  }

  const validateSubmit: SubmitHandler<FormValues> = (formValues, e) => {
    invariant(e);
    e.preventDefault();

    const atLeastOneCommodity = formValues.commodities.some(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ commodityId, ...keys }, index) => {
        const commodityKeys = Object.keys(keys) as Array<
          Exclude<keyof (typeof formValues.commodities)[number], "commodityId">
        >;
        return commodityKeys.every(
          (key) => formValues.commodities[index][key] !== "",
        );
      },
    );

    if (!atLeastOneCommodity) {
      form.setError("root.atLeastOneCommodity", {
        type: "value",
        message: t`Vyplňte všechny hodnoty alespoň pro jednu komoditu`,
      });

      return;
    }

    onSubmit(formValues, e);
  };

  return (
    <>
      <Metadata title={_(msg`Editor zpráv`)} />
      <form
        onSubmit={form.handleSubmit(validateSubmit)}
        autoComplete="off"
        className="h-full"
      >
        <ErrorMessage name="root.x" errors={form.formState.errors} />
        <div className="container mx-auto mt-5 h-full px-5">
          <fieldset className="flex h-full flex-col gap-y-8" disabled={busy}>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="headline">
                <Trans>Nadpis článku</Trans>
              </label>
              <input
                {...form.register("headline", {
                  required: t`Vyplňte nadpis článku`,
                })}
                autoFocus
                type="text"
                id="headline"
                className="h-10 w-full rounded border border-can-silver-cloud px-2 font-sans"
              />
              <ErrorMessage
                name="headline"
                errors={form.formState.errors}
                render={EditorErrorMessage}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <label htmlFor="summary">
                <Trans>Shrnutí článku</Trans>
              </label>
              <textarea
                {...form.register("summary")}
                rows={5}
                id="summary"
                name="summary"
                className="w-full rounded border border-can-silver-cloud px-2 font-sans"
              />
              <ErrorMessage
                name="summary"
                errors={form.formState.errors}
                render={EditorErrorMessage}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <label className="self-start" htmlFor="image">
                <Trans>Obrázek</Trans>
                <input
                  className="ml-2 rounded border border-can-silver-cloud px-2 py-1"
                  type="button"
                  onClick={handleImageReset}
                  value={_(msg`Smazat`)}
                />
              </label>
              <input
                onChange={handleImagePreview}
                ref={reportImageRef}
                id="image"
                name="image"
                className="h-10 self-start"
                type="file"
                accept="image/*"
              />
              <div ref={reportImagePreviewRef} />
            </div>
            <div className="flex gap-4">
              <div className="flex flex-col gap-y-2">
                <label htmlFor="dateFrom">
                  <Trans>Platnost od</Trans>
                </label>
                <input
                  {...form.register("dateFrom", {
                    required: t`Vyberte období, které článek pokrývá`,
                  })}
                  className="rounded border border-can-silver-cloud p-2"
                  id="dateFrom"
                  type="date"
                />
                <ErrorMessage
                  name="dateFrom"
                  render={EditorErrorMessage}
                  errors={form.formState.errors}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <label htmlFor="dateTo">
                  <Trans>Platnost do</Trans>
                </label>
                <input
                  {...form.register("dateTo", {
                    required: t`Vyberte období, které článek pokrývá`,
                  })}
                  id="dateTo"
                  type="date"
                  className="rounded border border-can-silver-cloud p-2"
                />
                <ErrorMessage
                  name="dateTo"
                  render={EditorErrorMessage}
                  errors={form.formState.errors}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <label htmlFor="language">
                  <Trans>Jazyk reportu</Trans>
                </label>
                <select
                  {...form.register("language", {
                    disabled: languages.status === "pending",
                    required: t`Vyberte jazyk`,
                  })}
                  id="language"
                  defaultValue=""
                  className={`${
                    languages.status === "pending" ? "animate-pulse" : undefined
                  } min-w-48 rounded border border-can-silver-cloud bg-white px-2`}
                >
                  <option value="" disabled>
                    ---
                  </option>
                  {languages.status === "success" ? (
                    <>
                      {languages.data.map((language) => (
                        <option key={language.id} value={language.id}>
                          {language.name}
                        </option>
                      ))}
                    </>
                  ) : null}
                </select>
                <ErrorMessage
                  render={EditorErrorMessage}
                  name="language"
                  errors={form.formState.errors}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-6">
              {commodities.status === "success"
                ? commodities.data.map((commodity, index) => (
                    <details data-testid="commodity-details" key={commodity.id}>
                      <summary className="flex gap-x-2 rounded bg-can-silver-cloud px-2 py-4">
                        <img src={commodity.icon} className="h-5 w-5" />
                        <span key={commodity.id}>{commodity.name}</span>
                        <ErrorMessage
                          name="root.atLeastOneCommodity"
                          errors={form.formState.errors}
                          render={EditorErrorMessage}
                        />
                      </summary>
                      <Spacer className="h-4" />
                      {
                        <Commodity
                          commodity={commodity.id.toString()}
                          index={index}
                        />
                      }
                    </details>
                  ))
                : Array.from({ length: 3 }).map((_, i) => (
                    <span
                      className="block w-24 animate-pulse border-r-2 bg-can-silver-cloud p-2 first-of-type:rounded-tl last-of-type:rounded-tr"
                      key={i}
                    >
                      &nbsp;
                    </span>
                  ))}
            </div>
            <input
              type="submit"
              value={_(msg`Uložit`)}
              className="self-center rounded bg-can-gradient-to-r-80 from-can-tranquil-azure to-can-mystic-aqua px-4 py-2 font-bold text-white disabled:opacity-50"
            />
          </fieldset>
        </div>
      </form>
    </>
  );
};

const useEditorForm = () => {
  return useFormContext<FormValues>();
};

export { EditorProvider, useEditorForm, path };
