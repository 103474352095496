import * as PurchasePrices from "./purchase-prices/purchase-prices";

const routes = [
  {
    path: PurchasePrices.path,
    element: (
      <PurchasePrices.Restoration>
        {(params) => (
          <PurchasePrices.PurchasePricesParams params={params}>
            <PurchasePrices.PurchasePrices />
          </PurchasePrices.PurchasePricesParams>
        )}
      </PurchasePrices.Restoration>
    ),
  },
];

export { routes };
