import { Trans } from "@lingui/macro";
import { Column } from "@tanstack/react-table";
import React from "react";

const Filter = <T,>({ column }: { column: Column<T, unknown> }) => {
  const { filterVariant } = column.columnDef.meta ?? {};
  const columnFilterValue = column.getFilterValue();
  const sortedUniqueValues = React.useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).toSorted(),
    [column],
  );

  if (filterVariant === "text") {
    return (
      <input
        type="text"
        className="mt-2 rounded-lg border md:max-w-[150px]"
        value={(columnFilterValue ?? "") as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
      />
    );
  }

  if (filterVariant === "select") {
    return (
      <select
        className="mt-2 rounded-lg border md:max-w-[150px]"
        onChange={(e) => column.setFilterValue(e.target.value)}
        value={columnFilterValue?.toString()}
      >
        <option value="">
          <Trans>Vše</Trans>
        </option>
        {sortedUniqueValues.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </select>
    );
  }
};

export { Filter };
