import {
  getCommodities,
  getLanguages,
  create,
  createReport,
  getReport,
  putReport,
  ReportUpdateTo,
} from "@/generated/api/news";
import { queryOptions } from "@tanstack/react-query";
import { sortBy } from "lodash";

const commoditiesQuery = () => {
  return queryOptions({
    queryKey: ["commodities"],
    queryFn: getCommodities,
  });
};

const languagesQuery = () => {
  return queryOptions({
    queryKey: ["languages"],
    queryFn: getLanguages,
    select: (data) => sortBy(data, (language) => language.localName),
  });
};

const reportQuery = (id: string) => {
  return queryOptions({
    queryKey: ["report", id],
    queryFn: () => getReport(parseInt(id)),
  });
};

const uploadImage = async (image: File | Blob) => {
  const formData = new FormData();
  formData.append("file", image);
  // @ts-expect-error types from swagger are actually wrong
  return await create(formData);
};

const reportCreateMutation = () => {
  return {
    mutationFn: (payload: ReportUpdateTo) => createReport(payload),
  };
};

const reportUpdateMutation = () => {
  return {
    mutationFn: ({ id, ...payload }: ReportUpdateTo & { id: number }) =>
      putReport(id, payload),
  };
};

export {
  commoditiesQuery,
  languagesQuery,
  reportQuery,
  uploadImage,
  reportCreateMutation,
  reportUpdateMutation,
};
