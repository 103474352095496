import { getEnvironmentVariable } from "@/common/services/env";
import { getAccessTokenSilently } from "../common/services/auth";

class Token {
  private token?: string;

  public async read({ forced }: { forced: boolean }) {
    if (forced) {
      this.token = await getAccessTokenSilently({ cacheMode: "off" });
      return this.token;
    }

    if (this.token) {
      return this.token;
    }

    this.token = await getAccessTokenSilently();

    return this.token;
  }

  public async readDigitalniSklady({ forced }: { forced: boolean }) {
    const token = getEnvironmentVariable("DIGITALNI_SKLADY_ACCESS_TOKEN");

    if (token) {
      return token;
    }

    return await this.read({ forced });
  }
}

const token = new Token();

export { Token, token };
