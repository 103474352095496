import BgPattern from "../../../assets/bg-pattern.svg?url";
import { useIsMobile } from "@/common/hooks/use-media-query";

const BackgroundContainer = ({ children }: React.PropsWithChildren) => {
  const isMobile = useIsMobile();

  return (
    <div
      className="h-full w-full px-6 sm:flex sm:items-center sm:justify-center sm:px-0"
      style={isMobile ? {} : { background: `url(${BgPattern})` }}
    >
      {children}
    </div>
  );
};

const Box = ({ children }: React.PropsWithChildren) => (
  <div className="bg-white pb-1 sm:h-auto sm:w-[423px] sm:rounded-2xl sm:px-6 sm:shadow-can-light-box">
    {children}
  </div>
);

BackgroundContainer.Box = Box;

export { BackgroundContainer };
