import { getEnvironmentVariable } from "@/common/services/env";
import { GraphQLClient, RequestMiddleware } from "graphql-request";
import { token } from "./token";
import { fetchRetry } from "./fetch-retry";

const requestMiddleware: RequestMiddleware = async (request) => {
  return {
    ...request,
    headers: {
      ...request.headers,
      // eslint-disable-next-line lingui/no-unlocalized-strings
      Authorization: `Bearer ${await token.readDigitalniSklady({ forced: false })}`,
      "Content-Type": "application/json",
    },
  };
};

const graphqlClient = new GraphQLClient(
  getEnvironmentVariable("DIGITALNI_SKLADY_API_URL"),
  {
    fetch: (input, init) =>
      fetchRetry({
        url: input.toString(),
        // @ts-expect-error err
        method: init?.method,
        // @ts-expect-error err
        data: JSON.parse(init?.body),
        // @ts-expect-error err
        headers: init?.headers,
        retries: 1,
      }),
    requestMiddleware,
  },
);

export { graphqlClient };
