import { useQuery } from "@tanstack/react-query";
import {
  RowData,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  getFacetedUniqueValues,
} from "@tanstack/react-table";
import { pendingUsersQuery } from "./pending-users.api";
import { DateTime } from "luxon";
import { ApprovalStatus } from "@/common/services/user";
import React from "react";
import { t } from "@lingui/macro";
import { Spinner } from "@/common/components/spinner/spinner";
import { useHasPermission } from "@/common/acl/guard/guard";
import { Link, Outlet } from "react-router-dom";
import { Metadata } from "@/common/components/metadata/metadata";
import { Table } from "../components/table/table";
import { UserActions } from "../components/user-actions/user-actions";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "select";
    label: () => string;
  }
}

type User = {
  createdAt: DateTime;
  id: number;
  email: string;
  company: {
    name: string;
    country: string;
    number: string;
    id: string;
  };
  approvalStatus: ApprovalStatus;
};

const columnHelper = createColumnHelper<User>();

const readonlyColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (info) => info.getValue(),
  }),

  columnHelper.accessor("email", {
    header: () => t`E-mail`,
    meta: {
      label: () => t`E-mail`,
      filterVariant: "text",
    },
    cell: (info) => {
      return (
        <Link
          className="text-can-forest-teal"
          to={info.row.getValue<User["id"]>("id").toString()}
        >
          {info.getValue()}
        </Link>
      );
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => t`Datum registrace`,
    meta: {
      label: () => t`Datum registrace`,
    },
    cell: (info) => info.getValue().toLocaleString(DateTime.DATE_SHORT),
    enableColumnFilter: false,
  }),

  columnHelper.accessor("company", {
    header: () => t`Společnost`,
    meta: {
      label: () => t`Společnost`,
      filterVariant: "text",
    },
    filterFn: (row, columnId, filter) => {
      const lowerCasedFilter =
        typeof filter === "string" ? filter.toLowerCase() : filter;
      const { name, number } =
        row.getValue<{ name: string; number: string }>(columnId) ?? {};
      return (
        name.toLowerCase().includes(lowerCasedFilter) ||
        number.toLowerCase().includes(lowerCasedFilter)
      );
    },
    cell: (info) => (
      <Link
        className="text-can-forest-teal"
        to={"company/" + info.getValue().id}
      >
        {info.getValue().country +
          " " +
          info.getValue().name +
          " / " +
          info.getValue().number}
      </Link>
    ),
  }),
];

const path = "pending-users";

// @ts-expect-error I don't care about the type here
const fallback = [];

const Users = () => {
  const pendingUsers = useQuery(pendingUsersQuery());
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const hasCompanyApprovePermission = useHasPermission("users:company:approve");
  const columns = React.useMemo(
    () => [
      ...readonlyColumns,
      ...(hasCompanyApprovePermission
        ? [
            columnHelper.display({
              id: "actions",
              cell: ({ row }) => <UserActions userId={row.getValue("id")} />,
            }),
          ]
        : []),
    ],
    [hasCompanyApprovePermission],
  );
  const table = useReactTable({
    // @ts-expect-error I don't care about the type here
    data: pendingUsers.data ?? fallback,
    columns,
    filterFns: {},
    state: {
      columnFilters,
      columnVisibility: {
        id: false,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnFiltersChange: setColumnFilters,
  });

  return (
    <>
      <Metadata title={t`Čekající na schválení`} />
      {pendingUsers.status === "pending" ? (
        <Spinner className="mx-auto mt-4" />
      ) : null}
      {pendingUsers.status === "success" ? (
        <Table>
          <Table.Head table={table} />
          <Table.Body table={table} />
        </Table>
      ) : null}
      <Outlet />
    </>
  );
};

export { Users, path };
