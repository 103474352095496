import { useSuspenseQuery } from "@tanstack/react-query";
import { Company, companiesQuery } from "./companies.api";
import {
  ColumnFiltersState,
  RowData,
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t } from "@lingui/macro";
import { countryCodeToFlag } from "@/common/utils/unicode";
import React from "react";
import { Table } from "../components/table/table";
import { Metadata } from "@/common/components/metadata/metadata";
import { Link, Outlet } from "react-router-dom";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "select";
    label: () => string;
  }
}
const path = "companies";
const columnHelper = createColumnHelper<Company>();
const fallbackTable = [] as Company[];

const Companies = () => {
  const companies = useSuspenseQuery(companiesQuery());
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("id", {
        header: () => null,
        cell: () => null,
      }),
      columnHelper.accessor("country", {
        header: () => t`Země`,
        cell: (info) => countryCodeToFlag(info.getValue()),
        meta: {
          label: () => t`Země`,
          filterVariant: "select",
        },
      }),
      columnHelper.accessor((row) => row.name + " / " + row.number, {
        cell: (info) => {
          const id = info.row.getValue<string>("id");

          return (
            <Link className="text-can-forest-teal" to={"./" + id}>
              {info.getValue()}
            </Link>
          );
        },
        header: () => t`Název / IČO`,
        id: "number",
        meta: {
          label: () => t`Společnost`,
          filterVariant: "text",
        },
        filterFn: (row, _, filter) => {
          const company = row.getValue<string>("number");
          const lowerCasedFilter =
            typeof filter === "string" ? filter.toLowerCase() : filter;
          return company.toLowerCase().includes(lowerCasedFilter);
        },
      }),
      columnHelper.accessor("plan.type", {
        header: () => t`Forma předplatného`,
        cell: (info) => info.getValue(),
        meta: {
          label: () => t`Forma předplatného`,
          filterVariant: "select",
        },
      }),
      columnHelper.accessor("plan.expiresAt", {
        header: () => t`Předplatné do`,
        cell: (info) => info.getValue(),
        meta: {
          label: () => t`Předplatné do`,
        },
      }),
    ],
    [],
  );

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const table = useReactTable({
    data: companies.data ?? fallbackTable,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnFiltersChange: setColumnFilters,
    state: {
      columnFilters,
      columnVisibility: {
        id: false,
        name: false,
      },
    },
  });

  return (
    <>
      <Metadata title={t`Správa společností`} />
      <Table>
        <Table.Head table={table} />
        <Table.Body table={table} />
      </Table>
      <Outlet />
    </>
  );
};

export { Companies, path };
