import { Helmet } from "react-helmet";

const Metadata = ({ title }: { title?: string }) => (
  <Helmet>
    {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
    <title>Clever˚Analytics&apos;{title ? ` | ${title}` : undefined}</title>
  </Helmet>
);

export { Metadata };
