import ReactGA from "react-ga4";
import { getEnvironmentVariable } from "@/common/services/env";

const MarketingProvider = () => {
  const googleAnalyticsId = getEnvironmentVariable("GOOGLE_ANALYTICS_ID");

  if (googleAnalyticsId) {
    ReactGA.initialize(googleAnalyticsId);
  }

  return null;
};

export { MarketingProvider };
