import { I18nProvider as LinguiI18nProvider } from "@lingui/react";
import { i18n as defaultI18n } from "@lingui/core";
import { dynamicActivate, getSupportedLanguage } from "@/common/services/i18n";
import React from "react";

const I18nProvider = ({
  i18n = defaultI18n,
  children,
  language,
}: React.PropsWithChildren<{
  language: string;
  i18n?: typeof defaultI18n;
}>) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setReady(false);
    dynamicActivate(i18n)(getSupportedLanguage(language)).then(() => {
      setReady(true);
    });
  }, [language, i18n]);

  return ready ? (
    <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>
  ) : null;
};

export { I18nProvider };
