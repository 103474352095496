import React from "react";
import invariant from "tiny-invariant";

type Screen = "profile" | "company" | "success" | "failure" | undefined;
const initialState: {
  screen: Screen;
  nextAction: Action;
  closeAction: Action;
} = {
  screen: undefined,
  nextAction: { type: "open", screen: "profile" },
  closeAction: { type: "toggle" },
};

const Context = React.createContext<
  | {
      state: typeof initialState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

const useUserSettings = () => {
  const ctx = React.useContext(Context);
  invariant(
    ctx,
    // eslint-disable-next-line lingui/no-unlocalized-strings
    "user context is only available within <UserSettingsProvider /> component",
  );

  return ctx;
};

type Action =
  | { type: "open"; screen: Screen }
  | { type: "toggle" }
  | { type: "noop" };

const reducer = (
  _state: typeof initialState,
  action: Action,
): typeof initialState => {
  switch (action.type) {
    case "open":
      {
        switch (action.screen) {
          case "success": {
            return {
              screen: action.screen,
              nextAction: { type: "open", screen: "profile" },
              closeAction: { type: "toggle" },
            };
          }
          case "failure": {
            return {
              screen: action.screen,
              nextAction: { type: "open", screen: "profile" },
              closeAction: { type: "toggle" },
            };
          }
          case "profile": {
            return {
              screen: action.screen,
              nextAction: { type: "open", screen: "company" },
              closeAction: { type: "toggle" },
            };
          }
          case "company": {
            return {
              screen: action.screen,
              nextAction: { type: "noop" },
              closeAction: { type: "open", screen: "profile" },
            };
          }
        }
      }
      break;
    case "toggle": {
      return {
        screen: undefined,
        nextAction: { type: "open", screen: "profile" },
        closeAction: { type: "noop" },
      };
    }
  }
  // eslint-disable-next-line lingui/no-unlocalized-strings
  throw new Error("You should have never got here");
};

const UserSettingsProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export { UserSettingsProvider, useUserSettings };
