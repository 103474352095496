import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./common/services/logger";
import { CommitHash } from "@/common/components/commit-hash/commit-hash";
import { AuthProvider } from "./common/providers/auth-provider";
import { App } from "./routes/app";
import { MarketingProvider } from "./common/providers/marketing-provider";
import { UserSettingsProvider } from "./common/providers/user-settings-provider";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <UserSettingsProvider>
        <App />
      </UserSettingsProvider>
      <MarketingProvider />
      <CommitHash />
    </AuthProvider>
  </React.StrictMode>,
);
