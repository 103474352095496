import { SearchParamsLink } from "@/common/components/search-params-link/search-params-link";
import { FullscreenModalLayout } from "@/routes/layout";
import TimesHexagon from "@icons/times-hexagon.svg?react";
import Cross from "@icons/cross.svg?react";
import { Spacer } from "@/common/components/spacer/spacer";
import { Button, primary } from "@/common/components/button/button";
import { Trans } from "@lingui/macro";

type Props = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  action?: React.ReactNode;
  headerButton?: React.ReactNode;
};

const path = "failed";
const Failed = (props: Props) => {
  return (
    <FullscreenModalLayout
      headerButton={
        props.headerButton ?? (
          <SearchParamsLink to=".." replace>
            <Cross />
          </SearchParamsLink>
        )
      }
    >
      <Spacer className="h-6" />
      <h1 className="text-center text-2xl font-bold text-can-forest-teal">
        {props.title ?? <Trans>Příkaz se nepodařilo nastavit</Trans>}
      </h1>
      <Spacer className="h-6" />
      <div className="self-center rounded-full bg-can-silver-gray p-11">
        <TimesHexagon />
      </div>
      <Spacer className="h-6" />
      <p className="text-center text-can-slate-blue-gray">
        {props.content ?? (
          <Trans>
            Bohužel někde nastala chyba a příkaz k prodeji se nepodařilo
            nastavit. Zkuste to později.
          </Trans>
        )}
      </p>
      <Button.Container>
        {props.action ?? (
          <SearchParamsLink to=".." replace className={primary}>
            <Trans>Přejít na detail příkazu</Trans>
          </SearchParamsLink>
        )}
      </Button.Container>
    </FullscreenModalLayout>
  );
};

export { path, Failed };
