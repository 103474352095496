import React from "react";
import ArrowUpRight from "@icons/arrow-up-right.svg?react";
import ArrowDownRight from "@icons/arrow-down-right.svg?react";
import ChartJS, { ScriptableContext, TooltipModel } from "chart.js/auto";
import { z } from "zod";
import { theme } from "@/common/theme";
import {
  currencyFormatter,
  numberFormatter,
} from "@/common/services/formatter";

type ChartData = {
  datetime: string;
  rate?: number;
};

type ChartProps = {
  currency: "CZK" | "EUR" | "USD";
  data: {
    current: ChartData[];
    next?: ChartData[];
  };
};

const Chart = ({ currency, data }: ChartProps) => {
  const chartRef = React.useRef<HTMLCanvasElement>(null);
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const lineRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ChartJS(chartRef.current, {
      type: "line",
      data: {
        datasets: (Object.keys(data) as (keyof typeof data)[]).map((key) => ({
          borderColor: getBorderColor,
          parsing: {
            yAxisKey: "rate",
            xAxisKey: "datetime",
          },
          name: key,
          data: data[key],
          borderWidth: 3,
          borderDash: key === "current" ? [] : [8, 2],
          pointRadius: 0,
          fill: key === "current",
          backgroundColor: getBackgroundColor,
        })),
      },
      options: {
        interaction: {
          mode: "nearest",
          axis: "x",
        },
        animation: false,
        layout: {
          padding: 0,
        },
        scales: {
          x: {
            border: {
              color: theme.colors["can-silver-cloud"],
            },
            grid: {
              color: "transparent",
            },
            ticks: {
              align: "inner",
              autoSkip: true,
            },
          },
          y: {
            border: {
              color: "transparent",
              dash: [2, 2],
            },
            grid: {
              tickLength: 10,
              tickColor: (context) =>
                context.index === 0
                  ? theme.colors["can-silver-cloud"]
                  : "transparent",
            },
            beginAtZero: false,
            ticks: {
              callback: (value) => {
                if (value === 0) {
                  return "";
                }
                return numberFormatter({
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(
                  typeof value === "number" ? value : parseFloat(value),
                );
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,
            position: "nearest",
            enabled: false,
            intersect: false,
            displayColors: false,
            external: (args) =>
              externalTooltipHandler(
                args,
                tooltipRef.current!,
                lineRef.current!,
              ),
            callbacks: {
              title: () => "",
              label: ({ raw }) => {
                const tooltipSchema = z.object({
                  rate: z.number(),
                  datetime: z.string(),
                });
                const safeRaw = tooltipSchema.parse(raw);

                return currencyFormatter(currency).format(safeRaw.rate);
              },
              afterBody: ([item]) => item.label,
            },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [currency, data]);

  return (
    <div className="relative">
      <div ref={lineRef} />
      <canvas className="mr-4 max-h-[280px]" ref={chartRef} />
      <div
        className="pointer-events-none absolute translate-x-[-50%] rounded-lg bg-[#4D5570CC] text-xs text-white"
        ref={tooltipRef}
      />
    </div>
  );
};

const Container = ({ children }: React.PropsWithChildren) => (
  <div className="flex w-full flex-col gap-y-5 self-start">{children}</div>
);

const Price = ({ children }: React.PropsWithChildren) => {
  return (
    <h3 className="self-start font-bold text-can-midnight-steel">{children}</h3>
  );
};

const Title = ({ children }: React.PropsWithChildren) => {
  return (
    <h2 className="flex min-h-7 items-center gap-x-3 text-xl font-bold text-can-midnight-steel">
      {children}
    </h2>
  );
};

const Trend = ({
  children,
  className,
}: {
  className?: string;
  children: number;
}) => {
  let Icon: React.ComponentType | undefined;
  let color = "text-can-midnight-steel";
  z.number().parse(children);

  if (children > 0) {
    Icon = ArrowUpRight;
    color = "text-can-forest-teal";
  }

  if (children < 0) {
    Icon = ArrowDownRight;
    color = "text-[#E55743]";
  }

  return (
    <b
      className={`${color} flex items-center gap-x-2 font-bold ${
        className ?? ""
      }`}
    >
      {currencyFormatter("EUR").format(children)}
      {Icon ? <Icon /> : null}
    </b>
  );
};

const Source = ({ children }: React.PropsWithChildren) => {
  return (
    <small className="basis-full text-xs text-can-slate-blue-gray">
      {children}
    </small>
  );
};

const ChartContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="flex min-h-[236px] w-full flex-col justify-center gap-y-5 self-start rounded-2xl p-4 pr-0 shadow-can-light-box">
      {children}
    </div>
  );
};

Chart.Container = Container;
Chart.Price = Price;
Chart.Trend = Trend;
Chart.Source = Source;
Chart.Title = Title;
Chart.ChartContainer = ChartContainer;

const getBackgroundColor = (context: ScriptableContext<"line">) => {
  const { ctx, chartArea } = context.chart;

  if (!chartArea) {
    return;
  }

  const gradient = ctx.createLinearGradient(
    chartArea.left,
    0,
    chartArea.right,
    chartArea.top,
  );
  gradient.addColorStop(0, `${theme.colors["can-tranquil-azure"]}66`);
  gradient.addColorStop(1, `${theme.colors["can-forest-teal"]}66`);
  return gradient;
};

const getBorderColor = (context: ScriptableContext<"line">) => {
  const { ctx, chartArea } = context.chart;

  if (!chartArea) {
    return;
  }

  const gradient = ctx.createLinearGradient(
    chartArea.left,
    0,
    chartArea.right,
    0,
  );
  gradient.addColorStop(0, theme.colors["can-tranquil-azure"]);
  gradient.addColorStop(1, theme.colors["can-forest-teal"]);
  return gradient;
};

const externalTooltipHandler = (
  context: { chart: ChartJS; tooltip: TooltipModel<"line"> },
  tooltipEl: HTMLDivElement,
  lineEl: HTMLDivElement,
) => {
  const { chart, tooltip } = context;

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    lineEl.style.opacity = "0";
    return;
  }

  if (tooltip.body) {
    // copy paste from <Outlook /> component
    // eslint-disable-next-line lingui/no-unlocalized-strings
    const currentLegend = `
      <span class="flex h-2 w-4 flex-col">
        <span class="h-0.5 w-4 bg-can-gradient-to-r-80 from-can-tranquil-azure to-can-mystic-aqua">&nbsp;</span>
        <span class="h-1.5 w-4 bg-[#BFDBED] inline-block">&nbsp;</span>
      </span>`;
    // copy paste from <Outlook /> component
    const nextLegend =
      // eslint-disable-next-line lingui/no-unlocalized-strings
      "<span class='h-2 w-4 inline-block border-t-2 border-dotted border-can-forest-teal bg-can-silver-gray'>&nbsp;</span>";

    tooltipEl.innerHTML = tooltip.body
      .map((body) => body.lines)
      .reduce((html, nextChunk, i) => {
        return (
          html +
          // eslint-disable-next-line lingui/no-unlocalized-strings
          "<div class='flex items-center gap-x-2'>" +
          (i === 0 ? currentLegend : nextLegend) +
          "<b>" +
          nextChunk +
          "</b>" +
          "</div>"
        );
      }, "" as string);

    tooltipEl.innerHTML += (tooltip.afterBody ?? []).reduce(
      (html: string, nextChunk: string) => {
        return html + nextChunk;
      },
      "",
    );
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  const yBox = chart.boxes.find((box) => "id" in box && box.id === "y");

  lineEl.style.opacity = "1";
  lineEl.style.position = "absolute";
  lineEl.style.left = `${tooltip.dataPoints.at(0)!.element.x}px`;
  lineEl.style.top = `${yBox?.top}px`;
  lineEl.style.height = `${yBox?.height}px`;
  lineEl.style.width = "1px";
  lineEl.style.backgroundColor = "#5DB4A1";

  tooltipEl.style.opacity = "1";
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.padding = "8px";
};

export { Chart };
