import { Button } from "@/common/components/button/button";
import Shop from "./assets/shop.svg?react";
import TimesHexagon from "@icons/times-hexagon.svg?react";
import { useIWantToTradeMutation } from "../purchase-prices.api";
import { useAuth0 } from "@auth0/auth0-react";
import invariant from "tiny-invariant";
import React from "react";
import Cross from "@icons/cross.svg?react";
import CheckCircle from "@icons/check-circle.svg?react";
import { useScrollbarToggle } from "@/common/hooks/use-scrollbar-toggle";
import { Trans } from "@lingui/macro";
import { getEnvironmentVariable } from "@/common/services/env";

const IWantToTradeDialog = () => {
  const { user } = useAuth0();
  const iWantToTrade = useIWantToTradeMutation();
  const [result, setResult] = React.useState<{
    type: "error" | "success";
  }>();
  useScrollbarToggle(!!result);

  const handleCloseResult = () => {
    setResult(undefined);
  };

  const handleClick = () => {
    invariant(user);
    invariant(user.email);
    invariant(user.name);
    const appMode = getEnvironmentVariable("APP_MODE");

    iWantToTrade.mutate(
      {
        environment: appMode,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        message: `[${appMode}]: ${user.name} s adresou ${user.email} má zájem o možnost obchodovat :moneybag:`,
        user: {
          name: user.name,
          email: user.email,
        },
      },
      {
        onError: () => {
          setResult({
            type: "error",
          });
        },
        onSuccess: () => {
          setResult({
            type: "success",
          });
        },
      },
    );
  };
  return (
    <>
      <aside className="flex w-full flex-col gap-6 rounded-t-[24px] bg-white p-4 shadow-can-light-box sm:flex-row sm:rounded-[24px]">
        <div className="self-center rounded-full bg-can-silver-gray p-8 sm:p-4">
          <Shop className="h-12 w-12 sm:h-6 sm:w-6" />
        </div>
        <div className="flex flex-col items-center sm:flex-grow sm:items-start">
          <h3 className="font-bold text-can-midnight-steel">
            <Trans>Toto jsou závazné výkupní ceny.</Trans>
          </h3>
          <p className="text-center text-xs text-can-slate-blue-gray sm:text-left">
            <Trans>
              Ceny garantované obchodníky, kteří vkládají cenu do systému. Pokud
              chcete obchodovat, klikněte na tlačítko níže.
            </Trans>
          </p>
        </div>
        <Button
          disabled={iWantToTrade.status === "pending"}
          onClick={handleClick}
          className="basis-full disabled:cursor-wait sm:flex-shrink-0 sm:basis-[30%] sm:self-center"
          variant="primary"
        >
          <Trans>Chci obchodovat</Trans>
        </Button>
      </aside>
      {result ? (
        <Result onClose={handleCloseResult}>
          {result.type === "success" ? (
            <Success>
              <Button
                className="mt-auto w-full sm:mt-0"
                onClick={handleCloseResult}
                variant="primary"
              >
                <Trans>OK</Trans>
              </Button>
            </Success>
          ) : null}
          {result.type === "error" ? (
            <Failure>
              <Button
                className="mt-auto w-full sm:mt-0"
                onClick={handleCloseResult}
                variant="primary"
              >
                <Trans>OK</Trans>
              </Button>
            </Failure>
          ) : null}
        </Result>
      ) : null}
    </>
  );
};

const Result = ({
  children,
  onClose,
}: React.PropsWithChildren<{ onClose: () => void }>) => {
  return (
    <>
      <div
        aria-hidden
        className="fixed inset-0 z-10 bg-[rgba(97,97,97,0.60)] backdrop-blur sm:ml-20"
      />
      <div className="fixed inset-0 z-[100] flex flex-col items-center gap-6 bg-white p-6 text-can-slate-blue-gray sm:z-20 sm:m-auto sm:h-fit sm:w-[400px] sm:rounded-2xl">
        <button onClick={onClose} className="self-start">
          <Cross />
        </button>
        {children}
      </div>
    </>
  );
};

const Failure = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <h1 className="text-center text-2xl font-bold text-can-forest-teal">
        <Trans>Žádost se nepodařilo odeslat</Trans>
      </h1>
      <div className="rounded-full bg-can-silver-gray p-11">
        <TimesHexagon />
      </div>
      <p className="text-center">
        <Trans>
          <strong>
            Vaši žádost o obchodování komodit se bohužel nepodařilo odeslat.
          </strong>{" "}
          Zopakujte prosím tuto akci znovu později.
        </Trans>
      </p>
      {children}
    </>
  );
};

const Success = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <h1 className="text-center text-2xl font-bold text-can-forest-teal">
        <Trans>Žádost úspěšně odeslána</Trans>
      </h1>
      <div className="rounded-full bg-can-silver-gray p-11">
        <CheckCircle />
      </div>
      <p className="text-center">
        <Trans>
          <strong>
            Vaše žádost o obchodování komodit byla úspěšně odeslána.
          </strong>{" "}
          Jakmile žádost zpracujeme, neprodleně vás kontaktujeme.
        </Trans>
      </p>
      {children}
    </>
  );
};

export { IWantToTradeDialog };
