import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { CompanyUser, companyQuery, companyUsersQuery } from "./company.api";
import { Link, useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import Cross from "@icons/cross.svg?react";
import { Trans, t } from "@lingui/macro";
import { countryCodeToFlag } from "@/common/utils/unicode";
import { Metadata } from "@/common/components/metadata/metadata";
import { Table } from "../components/table/table";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import {
  CompanyForm,
  Fields,
  getInitialValues,
} from "@/common/components/company-form/company-form";
import { splitCallingCodeAndCountry } from "@/common/utils/phone";
import { Spacer } from "@/common/components/spacer/spacer";
import { useUpdateCompanyMutation } from "@/common/components/user-settings/components/company-form/company-form.api";
import { Button } from "@/common/components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { CompanyToPlanType } from "@/generated/api/users";
import { DateTime } from "luxon";
import { InputField } from "@/common/components/form/input-field/input-field";
import { logger } from "@/common/services/logger";
import { useHasPermission } from "@/common/acl/guard/guard";
import { UserActions } from "../components/user-actions/user-actions";

const path = "company/:id";
const Company = ({ children }: React.PropsWithChildren) => {
  const id = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(id) }));

  return (
    <>
      <Metadata title={company.data.name} />
      <Link className="sm:hidden" to="..">
        <Cross />
      </Link>
      <h1 className="text-xl font-bold">
        {company.data.name} {countryCodeToFlag(company.data.country)}
      </h1>
      <ul className="flex flex-col gap-y-2">
        <li>
          <Trans context="subscription">Předplatné</Trans>
          {": "}
          {company.data.planType}
        </li>
        <li>
          <Trans>Konec předplatného</Trans>
          {": "}
          {company.data.planExpiresAt}
        </li>
      </ul>
      <hr />
      {children}
    </>
  );
};

const columnHelper = createColumnHelper<CompanyUser>();
const Users = () => {
  const id = useCompanyId();
  const hasCompanyApprovePermission = useHasPermission("users:company:approve");
  const users = useSuspenseQuery(companyUsersQuery({ id: parseInt(id) }));
  const columns = React.useMemo(
    () => [
      columnHelper.accessor("id", {
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("userEmail", {
        header: () => t`E-mail`,
        cell: (info) => info.getValue(),
        meta: {
          label: () => t`E-mail`,
        },
      }),
      columnHelper.accessor("approvalState", {
        header: () => t`Stav`,
        cell: (info) => info.getValue(),
        meta: {
          label: () => t`Stav`,
        },
      }),
      ...(hasCompanyApprovePermission
        ? [
            columnHelper.display({
              id: "actions",
              cell: ({ row }) => <UserActions userId={row.getValue("id")} />,
            }),
          ]
        : []),
    ],
    [hasCompanyApprovePermission],
  );
  const table = useReactTable({
    data: users.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        id: false,
      },
    },
  });
  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Uživatelé</Trans> ({users.data.length})
        </h2>
      </summary>
      {users.data.length > 0 ? (
        <Table>
          <Table.Head table={table} />
          <Table.Body table={table} />
        </Table>
      ) : null}
    </details>
  );
};

const Detail = () => {
  const queryClient = useQueryClient();
  const id = useCompanyId();
  const company = useSuspenseQuery(companyQuery({ id: parseInt(id) }));
  const updateCompany = useUpdateCompanyMutation();

  const handleSubmit = (data: Fields) => {
    updateCompany.mutate(
      {
        id: parseInt(id),
        address: data.address,
        vat: data.vat,
        companyForm: data.legalForm,
        contactName: data.contact.name,
        contactEmail: data.contact.email,
        contactPhone: {
          phoneNumber: data.contact.phone.phoneNumber,
          countryCode: splitCallingCodeAndCountry(
            data.contact.phone.countryCode,
          ).at(0)!,
        },
        contactPosition: data.contact.role,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries();
        },
      },
    );
  };

  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Informace</Trans>
        </h2>
      </summary>
      <Spacer className="h-6" />
      <CompanyForm
        onSubmit={handleSubmit}
        disabledFields={[]}
        initialValues={getInitialValues(company.data)}
      />
      <Button.Container>
        <Button
          disabled={updateCompany.status === "pending"}
          type="submit"
          variant="primary"
          form="company-form"
        >
          <Trans>Uložit</Trans>
        </Button>
      </Button.Container>
    </details>
  );
};

type SubscriptionFields = {
  plan: CompanyToPlanType;
  validUntil: DateTime;
};

const Subscription = () => {
  const form = useForm<SubscriptionFields>();

  const handleSubmit = (data: SubscriptionFields) => {
    logger.log(data);
  };

  return (
    <details>
      <summary>
        <h2 className="inline text-lg font-bold">
          <Trans>Předplatné</Trans>
        </h2>
      </summary>
      <Spacer className="h-6" />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="flex flex-col gap-y-4"
        >
          <div className="flex gap-x-2">
            <label
              className="flex shrink-0 basis-1/2 flex-col text-can-slate-blue-gray"
              htmlFor="phoneNumber"
            >
              <Trans>Předplatné</Trans>
              <select
                {...form.register("plan")}
                className="mt-2 h-[calc(theme(spacing.14)+2px)] rounded-lg font-bold group-has-[[role=alert]]:border-can-russet"
              >
                {Object.values(CompanyToPlanType).map((plan) => (
                  <option key={plan} value={plan}>
                    {plan}
                  </option>
                ))}
              </select>
            </label>
            <InputField<SubscriptionFields>
              label={<Trans>Platné do</Trans>}
              name="validUntil"
              type="date"
              options={{
                setValueAs: (v) => DateTime.fromISO(v),
                required: t`Prosím vyplňte platnost předplatného`,
                validate: (date) => {
                  if (!DateTime.isDateTime(date)) {
                    return t`Zadané datum není platné`;
                  }

                  return (
                    date > DateTime.now() || t`Vyplňte prosím budoucí datum`
                  );
                },
              }}
            />
          </div>
          <Button className="w-full" variant="primary">
            <Trans>Uložit</Trans>
          </Button>
        </form>
      </FormProvider>
    </details>
  );
};

const useCompanyId = () => {
  const { id } = useParams();
  // eslint-disable-next-line lingui/no-unlocalized-strings
  invariant(id, "company id is missing");
  return id;
};

export { Company, Users, Detail, Subscription, path };
