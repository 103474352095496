import { _delete, deleteUser, getUser } from "@/generated/api/users";
import { queryOptions, useMutation } from "@tanstack/react-query";

const userQuery = ({ id }: { id: number }) =>
  queryOptions({
    queryKey: ["user", id],
    queryFn: () => getUser(id),
  });

const useDeleteCompanyRelationMutation = () => {
  return useMutation({
    mutationKey: ["company"],
    mutationFn: ({ userId }: { userId: number }) => deleteUser(userId),
  });
};

const useWipeOutUserMutation = () => {
  return useMutation({
    mutationKey: ["user"],
    mutationFn: ({ ssoUserId }: { ssoUserId: string }) =>
      _delete(encodeURIComponent(ssoUserId)),
  });
};

export { userQuery, useDeleteCompanyRelationMutation, useWipeOutUserMutation };
