import { getEnvironmentVariable } from "./env";
import { UserSchema } from "./user";

type Flag = "premium" | "trading";
type Flags = Map<Flag, boolean>;

/**
 * Premium gets enabled when the user's company pays a monthly fee.
 * TODO use user profile to derive the data.
 */
const isPremiumEnabled = () => {
  return (
    getEnvironmentVariable("APP_MODE") === "nightly" ||
    window.location.host.includes("localhost") ||
    localStorage.getItem("feature-flag.premium") !== null
  );
};

/**
 * We only want trading to be available for a user with at least one czech company.
 */
const isTradingEnabled = (user: UserSchema) => {
  return (
    (user.user_metadata.can.companies ?? []).findIndex(
      (company) => company.countryCode === "CZ",
    ) > -1 || localStorage.getItem("feature-flag.trading") !== null
  );
};

const getFlags = (user: UserSchema): Flags => {
  const flags: Flags = new Map();
  flags.set("premium", isPremiumEnabled());
  flags.set("trading", isTradingEnabled(user));

  return flags;
};

export { getFlags };
export type { Flag, Flags };
