import { t } from "@lingui/macro";
import {
  UnauthorizedError,
  ServerError,
  ClientError,
  ForbiddenError,
  NotFoundError,
} from "./errors";
import { token } from "./token";

type Args = {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  params?:
    | ConstructorParameters<typeof URLSearchParams>[0]
    | Record<string, unknown>;
  data?: unknown;
  headers?: Record<string, string>;
  responseType?: string;
  retries?: number;
};

const fetchRetryJson = async <T>(args: Args): Promise<T> => {
  const result = await fetchRetry(args);

  if (result.headers.get("content-type")?.includes("application/json")) {
    return await result.json();
  } else {
    // @ts-expect-error because of DELETE report that returns an empty response
    return null;
  }
};

const fetchRetry = async ({
  url,
  method,
  headers = {},
  data,
  retries = 1,
}: Args): Promise<Response> => {
  let accessToken: string | null = null;

  /*
   * TODO drop this once auth0 integration is done on all envs
    desired state: const accessToken = await token.read({ forced: retries === 0 });
   */
  if (url.includes("digitalnisklady")) {
    if (!accessToken) {
      accessToken = await token.readDigitalniSklady({ forced: retries === 0 });
    }
  } else {
    accessToken = await token.read({ forced: retries === 0 });
  }
  const response = await fetch(
    url,
    // @ts-expect-error err
    {
      method,
      headers: {
        ...headers,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        Authorization: `Bearer ${accessToken}`,
      },
      ...(data
        ? {
            body:
              // eslint-disable-next-line lingui/no-unlocalized-strings
              headers["Content-Type"] === "application/json"
                ? JSON.stringify(data)
                : data,
          }
        : {}),
    },
  );

  if (response.status === 401) {
    if (retries > 0) {
      return await fetchRetry({
        url,
        method,
        headers,
        data,
        retries: 0,
      });
    }
  }

  if (!response.ok) {
    if (response.status === 401) {
      throw new UnauthorizedError();
    }

    if (response.status === 403) {
      throw new ForbiddenError();
    }

    if (response.status === 404) {
      throw new NotFoundError();
    }

    if (response.status >= 500) {
      throw new ServerError(t`Internal server error`);
    }

    if (response.status >= 400) {
      throw new ClientError();
    }

    throw response;
  }

  return response;
};

export { fetchRetry, fetchRetryJson };
export type { Args };
